import React, { useState, useMemo, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import debounce from 'lodash/debounce';
import { AutoComplete, Spin } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
// Api
import { GET_MENTIONS_STORES } from 'api/store/queries';
// Types
import { UserRole } from 'api/graphql-global-types';
import {
  GetMentionsStores,
  GetMentionsStoresVariables,
} from 'api/store/types/GetMentionsStores';
// Styles
import styles from './MillionsMentionsSelect.module.scss';

type MillionsMentionsProps = {
  mention: any | null;
  index: number;
  onChange: any;
  handleMillionsMentionRemove: (index: any) => void;
};

const MillionsMentionsSelect = ({
  mention,
  onChange,
  index,
  handleMillionsMentionRemove,
}: MillionsMentionsProps) => {
  const [selectedOption, setSelectedOption] = useState<any | null>(null);
  const [asyncOptions, setAsyncOptions] = useState<Array<any>>([]);
  const [autoCompleteValue, setAutoCompleteValue] = useState<any>('');
  const [inputFocused, setInputFocused] = useState(false);
  const isEdit = !!mention?.id;

  useEffect(() => {
    if (mention) {
      const formattedMentionsValue = {
        label: mention?.store?.storeDetails?.storeName || '',
        value: mention?.store?.storeDetails?.storeId || '',
        avatarURL: mention?.store?.storeDetails?.avatarURL || '',
      };
      setSelectedOption(formattedMentionsValue);
      setAutoCompleteValue('');
    }
  }, [mention]);

  const [getStores, { loading: getStoresLoading, data }] = useLazyQuery<
    GetMentionsStores,
    GetMentionsStoresVariables
  >(GET_MENTIONS_STORES);

  useEffect(() => {
    const options =
      data?.stores.entities.map(({ id, storeDetails }) => ({
        value: id,
        label: storeDetails?.storeName || '',
        avatarURL: storeDetails?.avatarURL || '',
      })) || [];
    setAsyncOptions(options);
  }, [data]);

  const handleSelectStoreId = (value: string, option: any): void => {
    setSelectedOption(option);
    setInputFocused(false); // Hide overlay on selection

    const selectedStore = {
      store: {
        storeDetails: {
          storeId: option.value,
          storeName: option.label,
          avatarURL: option.avatarURL,
        },
      },
      mentionType: 'MEDIA_POST',
      targetType: 'REGISTERED',
    };

    onChange(selectedStore, index);
  };

  const mentionRemove = (index: number) => {
    setSelectedOption(null);
    setAutoCompleteValue(''); // Clear input
    handleMillionsMentionRemove(index);
  };

  // Custom render option with avatar and label
  const renderOption = (option: any) => (
    <div className={styles.optionContainer}>
      <img
        src={option.avatarURL}
        alt={option.label}
        className={styles.avatar}
      />
      <span>{option.label}</span>
    </div>
  );

  // Render selected value inside AutoComplete
  const renderSelectedValue = (option: any) => (
    <button
      type="button"
      className={styles.selectedValue}
      onClick={() => !isEdit && setInputFocused(true)}
    >
      {option.avatarURL && (
        <img
          src={option.avatarURL || '/favicon.png'}
          alt={option.label || ''}
          className={styles.avatar}
        />
      )}
      <span>{option.label || ''}</span>
    </button>
  );

  const handleOnSearch = useMemo(() => {
    const loadOptions = (storeName: string) => {
      setAsyncOptions([]);

      getStores({
        variables: {
          storeRoles: [UserRole.Athlete, UserRole.Organization],
          input: { storeName },
        },
      });
    };

    return debounce(loadOptions, 400);
  }, [getStores]);

  const handleAutoCompleteChange = (value: string): void => {
    setAutoCompleteValue(value);
  };

  return (
    <div className={styles.root}>
      <div className={styles.autoCompleteWrapper}>
        {selectedOption?.value &&
          !inputFocused && ( // Show only when input is not focused
            <div className={styles.overlay}>
              {renderSelectedValue(selectedOption)}
            </div>
          )}

        <AutoComplete
          placeholder="Search by store name"
          notFoundContent={getStoresLoading ? <Spin size="small" /> : null}
          value={autoCompleteValue}
          disabled={isEdit}
          options={asyncOptions.map((option) => ({
            value: option.value,
            label: renderOption(option),
          }))}
          onSearch={handleOnSearch}
          onChange={handleAutoCompleteChange}
          onSelect={(value: string) => {
            const selectedOption = asyncOptions.find(
              (option) => option.value === value
            );
            if (selectedOption) handleSelectStoreId(value, selectedOption);
          }}
          className={styles.selectMillionsMention}
          onFocus={() => setInputFocused(true)}
          onBlur={() => setInputFocused(false)}
        />
      </div>

      {selectedOption && (
        <button
          className={styles.deleteButton}
          onClick={() => mentionRemove(index)}
          type="button"
          aria-label="Delete mention"
        >
          <DeleteOutlined className={styles.deleteIcon} />
        </button>
      )}
    </div>
  );
};

export default MillionsMentionsSelect;

import React, { useRef, useState } from 'react';
import { useMutation } from '@apollo/client';
import {
  Button,
  Form,
  Typography,
  Input,
  Space,
  Tag,
  AutoComplete,
} from 'antd';
import { UploadOutlined, LoadingOutlined } from '@ant-design/icons';
import { useSetState } from 'react-use';
import { DeleteOutlined } from '@ant-design/icons';
// Api
import {
  COMPLETE_POST_EDITED_VIDEO_MULTIPART_UPLOAD,
  CREATE_EDITED_VIDEO,
  CREATE_PRESIGNED_URLS,
  EDIT_UPLOADED_VIDEO_REQUEST_INFO,
  INIT_EDITED_VIDEO_MULTIPART_UPLOAD,
  REUPLOAD_EDITED_VIDEO,
} from 'api/videoLab/mutation';
// Types
import {
  UpdateEditedVideoById,
  UpdateEditedVideoByIdVariables,
} from 'api/videoLab/types/UpdateEditedVideoById';
import {
  CreatePresignedUrls,
  CreatePresignedUrlsVariables,
} from 'api/videoLab/types/CreatePresignedUrls';
import {
  ReuploadEditedVideoInput,
  UploadEditedVideo,
  VideoEditRequestSubmissionStatus,
} from 'api/graphql-global-types';
import {
  CreateEditedVideo,
  CreateEditedVideoVariables,
} from 'api/videoLab/types/CreateEditedVideo';
import {
  InitEditedVideoMultipartUpload,
  InitEditedVideoMultipartUploadVariables,
} from 'api/videoLab/types/InitEditedVideoMultipartUpload';
import {
  CompletePostEditedVideoMultipartUpload,
  CompletePostEditedVideoMultipartUploadVariables,
} from 'api/videoLab/types/CompletePostEditedVideoMultipartUpload';
import { GetVideoEditRequests_getVideoEditRequests_entities_editedVideos } from 'api/videoLab/types/GetVideoEditRequests';
import {
  ReuploadEditedVideo,
  ReuploadEditedVideoVariables,
} from 'api/videoLab/types/ReuploadEditedVideo';
import VideoCommentsModal, {
  VideoCommentModalData,
} from '../VideoCommentsModal/VideoCommentsModal';
// Helpers
import { bytesToSize } from 'helpers/bytesToSize';
import {
  VideoEditingRequestsFormattedData,
  calculateChunkSize,
  uploadImageToS3,
} from 'helpers/videoEditingRequest';
import { Uploader } from 'helpers/uploader';
import { tagStyles } from 'uiShared/TagWithButtons/helpers';
// Ui
import UploadImageFile from 'ui/UploadImageFile/UploadImageFile';
import { errorNotification, successNotification } from 'ui/Notification';
// Styles
import styles from './UploadFinalizedVideo.module.scss';

const { Text } = Typography;
const { TextArea } = Input;

const MAX_VIDEO_SIZE_B = 1024 * 1024 * 1024 * 1024; // equals to 1TB, our current limit

// for initial phase we will have Interview only
export const videoTypeOptions = [{ label: 'Interview', value: 'Interview' }];

export type AutoCompleteOption = {
  value: string | number;
  label: string;
};

type UploadFinalizedVideoProps = {
  isAdmin: boolean | null;
  isRejected?: boolean;
  onClose: () => void;
  videoEditRequest: VideoEditingRequestsFormattedData | null;
  handleRefetchVideos?: () => void;
  selectedEditedVideo: GetVideoEditRequests_getVideoEditRequests_entities_editedVideos | null;
  isViewOnly?: boolean;
};

const initialVideoStateValues = {
  videoFile: null,
  videoPreviewURL: null,
  cropArea: null,
  isScrollDisabled: false,
  videoFileSize: 0,
  showVideoPreview: false,
  isCropNotSupported: false,
  isCropVisible: false,
};

export const UploadFinalizedVideo = ({
  onClose,
  isAdmin,
  isRejected = false,
  videoEditRequest,
  handleRefetchVideos,
  selectedEditedVideo,
  isViewOnly = false,
}: UploadFinalizedVideoProps): JSX.Element => {
  const [form] = Form.useForm();
  const uploadVideoRef = useRef<HTMLInputElement | null>(null);
  const [state, setState] = useSetState<{
    videoFile: File | null;
    videoPreviewURL: string | null;
    isScrollDisabled: boolean;
    videoFileSize: number;
    showVideoPreview: boolean;
  }>(initialVideoStateValues);

  const [selectedStores, setSelectedStores] = useState<AutoCompleteOption[]>(
    []
  );
  const [selectedStoreInputValue, setSelectedStoreInputValue] =
    useState<string>('');

  const [videoProgressContainer, setVideoProgressContainer] =
    useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showVideoCommentsModal, setShowVideoCommentsModal] =
    useState<boolean>(false);

  const { videoFile, videoFileSize } = state;

  const [initEditedVideoMultipartUpload] = useMutation<
    InitEditedVideoMultipartUpload,
    InitEditedVideoMultipartUploadVariables
  >(INIT_EDITED_VIDEO_MULTIPART_UPLOAD);

  const [editVideoRequestInfo, { loading: editVideoRequestInfoLoading }] =
    useMutation<UpdateEditedVideoById, UpdateEditedVideoByIdVariables>(
      EDIT_UPLOADED_VIDEO_REQUEST_INFO
    );

  const [completePostEditedVideoMultipartUpload] = useMutation<
    CompletePostEditedVideoMultipartUpload,
    CompletePostEditedVideoMultipartUploadVariables
  >(COMPLETE_POST_EDITED_VIDEO_MULTIPART_UPLOAD);

  const [createPresignedUrls] = useMutation<
    CreatePresignedUrls,
    CreatePresignedUrlsVariables
  >(CREATE_PRESIGNED_URLS);

  const [createEditedVideo] = useMutation<
    CreateEditedVideo,
    CreateEditedVideoVariables
  >(CREATE_EDITED_VIDEO);

  const [reuploadEditedVideo] = useMutation<
    ReuploadEditedVideo,
    ReuploadEditedVideoVariables
  >(REUPLOAD_EDITED_VIDEO);

  const handleUploadVideoError = (error: Error) => {
    errorNotification((error as Error).message || 'Error uploading video!');
  };

  const handleReturnUploadPercentage = (percentage: string) => {
    setVideoProgressContainer('Uploading: ' + percentage);
  };

  const handleVideoSubmit = async () => {
    const videoFileName = videoFile?.name;
    setIsLoading(true);

    if (videoFile) {
      try {
        const thumbnailKey = (await uploadImageStep()) || '';
        const chunkSize = calculateChunkSize((videoFile as File).size);
        const contentType = (videoFile as File).type;
        const numberOfParts = Math.ceil(videoFile.size / chunkSize);
        const fileExtension = (videoFile as File).name.split('.').pop() || '';
        const { data } = await initEditedVideoMultipartUpload({
          variables: {
            input: {
              contentType,
              fileExt: fileExtension,
              partCnt: numberOfParts,
              videoEditRequestId: videoEditRequest?.id || '',
            },
          },
        });

        if (data?.initEditedVideoMultipartUpload) {
          let fileKey: string;

          const uploader = new Uploader({
            chunkSize: chunkSize,
            file: new File([videoFile], `${videoFileName}.${fileExtension}`),
            initData: data.initEditedVideoMultipartUpload,
          });

          uploader.start();

          uploader
            .onProgress(({ percentage: newPercentage }) => {
              handleReturnUploadPercentage(`${newPercentage}%`);
            })
            .onComplete(async (input) => {
              fileKey = input.fileKey;

              await completePostEditedVideoMultipartUpload({
                variables: {
                  input,
                },
              });

              setIsLoading(false);
              if (isRejected) {
                const acceptedStoreIds = selectedStores.map(
                  (store) => store.value
                ) as string[];

                const reuploadEditedVideoInput: ReuploadEditedVideoInput = {
                  acceptedStoreIds: [...acceptedStoreIds],
                  editedVideoId: selectedEditedVideo?.id || '',
                  id: videoEditRequest?.id || '',
                  video: { key: fileKey || '' },
                  body: form.getFieldValue('body'),
                  title: form.getFieldValue('title'),
                  thumbnailKey: thumbnailKey,
                };

                const reuploadEditedVideoResponse = await reuploadEditedVideo({
                  variables: {
                    input: reuploadEditedVideoInput,
                  },
                });

                if (reuploadEditedVideoResponse) {
                  successNotification('Your video is updated');
                  onClose();
                  handleRefetchVideos && handleRefetchVideos();
                }
              } else {
                const createEditedVideoInput: UploadEditedVideo = {
                  id: videoEditRequest?.id || '',
                  video: { key: fileKey || '' },
                  body: form.getFieldValue('body'),
                  title: form.getFieldValue('title'),
                  thumbnailKey: thumbnailKey,
                };

                const createEditedVideoResponse = await createEditedVideo({
                  variables: {
                    input: createEditedVideoInput,
                  },
                });

                if (createEditedVideoResponse) {
                  successNotification('Your video is uploaded');
                  onClose();
                  handleRefetchVideos && handleRefetchVideos();
                }
              }
            })
            .onError(handleUploadVideoError);
          setVideoProgressContainer('');
        }
      } catch (error) {
        handleUploadVideoError(error as unknown as Error);
        setIsLoading(false);
        setVideoProgressContainer('');
      }
    }
  };

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (file) {
      const fileURL = URL.createObjectURL(file);

      setState({
        videoFile: file,
        videoPreviewURL: fileURL,
        videoFileSize: file.size,
        showVideoPreview: false,
      });

      form.setFieldsValue({ video: fileURL });
    }
  };

  const isVideoFileSizeError = videoFileSize > MAX_VIDEO_SIZE_B;

  const videoSizeErrorMessage = isVideoFileSizeError ? (
    <span>
      The size of uploaded video is more than our limit (
      <strong>{bytesToSize(videoFileSize)}</strong>
      ). Please, select video smaller than 1 TB.
    </span>
  ) : null;

  const normFile = (e: any) => {
    if (e && Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const handleUploadVideoButtonClick = () => {
    uploadVideoRef.current?.click();
  };

  const handleEditTitleAndDescription = async () => {
    const values = form.getFieldsValue();

    try {
      await editVideoRequestInfo({
        variables: {
          input: {
            editedVideoId: selectedEditedVideo?.id || '',
            body: values.body,
            title: values.title,
          },
        },
      });

      successNotification('Information updated successfully');
      handleRefetchVideos && handleRefetchVideos();
      onClose();
    } catch (err) {
      errorNotification((err as Error)?.message || 'Something went wrong');
    }
  };

  const handleFormSubmit = async () => {
    if (isAdmin && selectedEditedVideo) {
      await handleEditTitleAndDescription();
    } else {
      await handleVideoSubmit();
    }
  };

  const uploadImageStep = async () => {
    // upload if there is anything to upload
    if (form.getFieldValue('thumbnailKey')[0]?.originFileObj) {
      try {
        const getUrl = await createPresignedUrls({
          variables: {
            input: {
              files: [
                {
                  contentType:
                    form.getFieldValue('thumbnailKey')[0]?.originFileObj.type ||
                    '',
                  ext:
                    form
                      .getFieldValue('thumbnailKey')[0]
                      ?.originFileObj.name.split('.')
                      .pop() || '',
                },
              ],
            },
          },
        });
        if (getUrl?.data) {
          const uploadImageData = await uploadImageToS3(
            getUrl.data.createPresignedUrls[0],
            {
              data_url: '',
              file: form.getFieldValue('thumbnailKey')[0]?.originFileObj,
            }
          );
          return uploadImageData;
        }
      } catch (error) {
        errorNotification((error as Error).message || 'Error uploading image!');
      }
    } else {
      // if there is nothing to upload, use the original thumbnail key
      return selectedEditedVideo?.thumbnailKey;
    }
  };

  const initialFormValues = {
    title: selectedEditedVideo?.title || null,
    body: selectedEditedVideo?.body || null,
  };

  const isReadOnly = !isAdmin && !!selectedEditedVideo;

  const handleSelectStore = (
    value: string,
    option: AutoCompleteOption
  ): void => {
    setSelectedStores([...selectedStores, option]);

    setSelectedStoreInputValue('');
  };

  const handleStoreChange = (e: string) => {
    setSelectedStoreInputValue(e);

    const updatedSelectedStores = [
      ...selectedStores,
      selectedStoreInputValue.trim(),
    ];
    form.setFieldsValue({
      storeNames: updatedSelectedStores,
    });
  };

  const handleRemoveStore = (storeId: string | number) => {
    const updatedSelectedStores = selectedStores.filter(
      (storeOption) => storeOption.value !== storeId
    );
    setSelectedStores(updatedSelectedStores);
    form.setFieldsValue({
      storeNames: updatedSelectedStores,
    });
  };

  const stores: AutoCompleteOption[] = [];
  const commentsModalData: VideoCommentModalData[] = [];

  // the select input is supposed to show all the shops that accepted the video request
  // so we can update them too..the rejected ones are automatically updated
  selectedEditedVideo?.reviewSubmissions.forEach((item) => {
    if (item.status === VideoEditRequestSubmissionStatus.Approved) {
      stores.push({
        value: item.store.id,
        label: item.store.storeDetails?.storeName || '',
      });
    }

    item.rejections?.forEach((rejection) => {
      commentsModalData.push({
        storeName: item.store.storeDetails?.storeName || '',
        feedback: rejection.reason,
        type: VideoEditRequestSubmissionStatus.Rejected,
        assignedToStoreAt: item.assignedToStoreAt,
      });
    });
  });

  const handleVideoCommentsModalClose = () => {
    setShowVideoCommentsModal(false);
  };

  const handleOpenShowVideoCommentsModal = () => {
    setShowVideoCommentsModal(true);
  };

  const showFeedback = !!commentsModalData.length;

  return (
    <Form
      form={form}
      layout="horizontal"
      name="createVideoRequestForm"
      autoComplete="off"
      onFinish={handleFormSubmit}
      initialValues={initialFormValues}
    >
      <div>
        <Form.Item
          name="title"
          label={<Text>Title</Text>}
          rules={[
            {
              required: true,
              message: 'Please enter the title',
            },
          ]}
        >
          <Input readOnly={isReadOnly} />
        </Form.Item>

        <Form.Item
          name="body"
          label={<Text>Body</Text>}
          rules={[
            {
              required: true,
              message: 'Please enter the description',
            },
          ]}
        >
          <TextArea
            autoSize={{ minRows: 3, maxRows: 10 }}
            readOnly={isReadOnly}
          />
        </Form.Item>

        {showFeedback && (
          <div className={styles.feedback}>
            <p>Feedback:</p>
            <Button
              className={styles.feedbackButton}
              type="primary"
              onClick={handleOpenShowVideoCommentsModal}
            >
              Show
            </Button>
          </div>
        )}

        {isRejected && !!stores?.length && (
          <Form.Item label={<Text>Stores names</Text>} name="storeNames">
            <AutoComplete
              id="storeNames"
              className={styles.autoComplete}
              placeholder="Accepted stores"
              options={stores}
              value={selectedStoreInputValue}
              onSelect={handleSelectStore}
              onChange={(e) => handleStoreChange(e)}
            />

            <div style={{ marginTop: '10px' }}>
              {selectedStores.map((option) => (
                <Tag key={option.value} style={tagStyles}>
                  <div className={styles.textLabel}>{option.label}</div>
                  <Button
                    type="link"
                    icon={<DeleteOutlined />}
                    onClick={() => handleRemoveStore(option.value)}
                  />
                </Tag>
              ))}
            </div>
          </Form.Item>
        )}

        <Form.Item
          name="thumbnailKey"
          label="Thumbnail image"
          valuePropName="thumbnailKey"
          getValueFromEvent={normFile}
          initialValue={selectedEditedVideo?.thumbnailUrl}
          rules={[
            {
              required: true,
              message: 'Please upload the thumbnail image',
            },
          ]}
        >
          <UploadImageFile
            hideButton={
              !!selectedEditedVideo?.thumbnailUrl && (isViewOnly || !isRejected)
            }
            buttonTitle="Upload thumbnail image"
            imageAlt="Thumbnail image"
            defaultFileUrl={selectedEditedVideo?.thumbnailUrl}
            hideRemoveIcon={
              Boolean(selectedEditedVideo) && (isViewOnly || !isRejected)
            }
          />
        </Form.Item>

        {!isViewOnly && (
          <p>
            Attach your edited video here. Accepted video submission format:
            .mp4.
          </p>
        )}

        {/* display video if this is view only mode (details modal) or if it's accepted 
         we don't display video for rejected, instead we show the upload button */}
        {selectedEditedVideo && (isViewOnly || !isRejected) ? (
          <Space className="video-modal-container" direction="vertical">
            <Form.Item
              label={<Text>Finalized Video</Text>}
              rules={[
                {
                  required: true,
                  message: 'Please upload the finalized video',
                },
              ]}
            >
              <Tag
                key={selectedEditedVideo.id}
                onClick={() =>
                  window.open(selectedEditedVideo?.videoUrl || '', '_blank')
                }
                style={{
                  border: '1px solid #d9d9d9',
                  color: '#1890ff',
                  height: '22px',
                  maxWidth: '600px',
                  cursor: 'pointer',
                }}
              >
                {selectedEditedVideo.title || `finalized_video`}
              </Tag>
            </Form.Item>
          </Space>
        ) : (
          <Space className="video-modal-container" direction="vertical">
            <Form.Item
              name="video"
              label={<Text>Finalized Video</Text>}
              rules={[
                {
                  required: true,
                  message: 'Please upload the finalized video',
                },
              ]}
              required
            >
              <div>
                <input
                  name="video"
                  type="file"
                  accept="video/mp4"
                  hidden
                  onChange={handleFileUpload}
                  ref={uploadVideoRef}
                  aria-label="upload video"
                />

                {state.videoFile ? (
                  <Tag
                    key={state.videoFile.name}
                    style={{
                      border: '1px solid #d9d9d9',
                      color: '#1890ff',
                      height: '22px',
                      maxWidth: '600px',
                      cursor: 'pointer',
                    }}
                  >
                    {state.videoFile.name || `finalized_video`}
                  </Tag>
                ) : (
                  <button type="button" onClick={handleUploadVideoButtonClick}>
                    {isLoading ? <LoadingOutlined /> : <UploadOutlined />}

                    <span>Upload video</span>
                  </button>
                )}
                <p>{videoSizeErrorMessage}</p>
              </div>

              <p>{videoProgressContainer}</p>
            </Form.Item>
          </Space>
        )}
      </div>

      {(!selectedEditedVideo || (!isViewOnly && isRejected)) && (
        <Form.Item>
          <Button
            htmlType="submit"
            type="primary"
            disabled={isVideoFileSizeError || isLoading}
            loading={isLoading}
          >
            Submit
          </Button>
        </Form.Item>
      )}
      {isAdmin && (
        <div>
          <p>Edit finalized video title or description here...</p>

          <Form.Item className={styles.saveButtonContainer}>
            <Button
              htmlType="submit"
              type="primary"
              loading={editVideoRequestInfoLoading}
              disabled={
                !selectedEditedVideo?.title || !selectedEditedVideo?.body
              }
            >
              Update edited video
            </Button>
          </Form.Item>
        </div>
      )}
      <VideoCommentsModal
        show={showVideoCommentsModal}
        onClose={handleVideoCommentsModalClose}
        data={commentsModalData}
      />
    </Form>
  );
};

export default UploadFinalizedVideo;

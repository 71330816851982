import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import moment from 'moment';
import { useHistory, useParams } from 'react-router-dom';
// Api
import { GET_ARTICLES } from 'api/articles/queries';
// Types
import {
  GetArticles,
  GetArticlesVariables,
  GetArticles_getArticles_entities,
} from 'api/articles/types/GetArticles';
import { ArticleStatus, ArticleTypes } from 'api/graphql-global-types';
// UI
import Table from 'ui/Table';
import { Tabs, Button, Modal, Spin } from 'antd';
// Constants
import { commonTimeFormat } from 'constants/global';
import { ARTICLES } from 'constants/routes';
// Components
import CreateEditSponsoredArticles from './components/CreateEditSponsoredArticles/CreateEditSponsoredArticles';
import SponsoredArticlesDetails from './components/SponsoredArticlesDetails/SponsoredArticlesDetails';
import Actions from './components/Actions/Actions';
// Styles
import styles from './Articles.module.scss';

const { TabPane } = Tabs;

const DEFAULT_NUMBER_ITEMS_PER_PAGE = 10;
const DEFAULT_CURRENT_PAGE = 1;

const Articles: React.FC = () => {
  const history = useHistory();

  const { itemsPerPage, pageNumber, articleType } = useParams<{
    articleType: ArticleTypes | undefined;
    itemsPerPage: string | undefined;
    pageNumber: string | undefined;
  }>();

  const [pageSize, setPageSize] = useState<number>(
    Number(itemsPerPage) || DEFAULT_NUMBER_ITEMS_PER_PAGE
  );
  const [currentPage, setCurrentPage] = useState<number>(
    Number(pageNumber) || DEFAULT_CURRENT_PAGE
  );

  const tabKey = articleType;

  const [detailsModalData, setDetailsModalData] =
    useState<GetArticles_getArticles_entities | null>(null);
  const [creationModalVisibility, setCreationModalVisibility] =
    useState<boolean>(false);
  const [updateModalVisibility, setUpdateModalVisibility] =
    useState<GetArticles_getArticles_entities | null>(null);

  const { data, loading } = useQuery<GetArticles, GetArticlesVariables>(
    GET_ARTICLES,
    {
      variables: {
        input: {
          limit: pageSize,
          offset: (currentPage > 1 ? currentPage - 1 : 0) * pageSize,
        },
      },
      fetchPolicy: 'cache-and-network',
    }
  );

  const showDetailsModal = (record: GetArticles_getArticles_entities) => {
    setDetailsModalData(record);
  };

  const hideDetailsModal = () => {
    setDetailsModalData(null);
  };

  const handleCreateArticle = () => {
    setCreationModalVisibility(true);
  };

  const handleOpenEditSponsoredArticleModal = (
    record: GetArticles_getArticles_entities
  ) => {
    setUpdateModalVisibility(record);
  };

  const hideModal = () => {
    setCreationModalVisibility(false);
    setUpdateModalVisibility(null);
  };

  const columns = [
    {
      title: 'Article Id',
      dataIndex: 'id',
      align: 'left' as const,
      width: 130,
      render: function ActionsWrap(
        id: string,
        record: GetArticles_getArticles_entities
      ) {
        return (
          <span
            onKeyDown={() => showDetailsModal(record)}
            onClick={() => showDetailsModal(record)}
            className={styles.detailsModalLink}
            role="button"
            tabIndex={0}
          >
            {id}
          </span>
        );
      },
    },
    {
      title: 'Article Title',
      dataIndex: 'title',
      align: 'left' as const,
      width: 120,
    },
    {
      title: 'Profile Name',
      dataIndex: ['store', 'storeDetails', 'storeName'],
      align: 'left' as const,
      width: 120,
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      align: 'left' as const,
      width: 120,
      render: (createdAt: string) => moment(createdAt).format(commonTimeFormat),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      align: 'center' as const,
      width: 100,
      render: (status: ArticleStatus) => {
        if (status === ArticleStatus.Active) {
          return <p className={styles.published}>Published</p>;
        } else {
          return <p className={styles.deactivated}>Deactivated</p>;
        }
      },
    },
    {
      title: 'Action',
      dataIndex: 'id',
      fixed: 'right' as const,
      align: 'center' as const,
      width: 80,
      render: function ActionsWrap(
        id: string,
        record: GetArticles_getArticles_entities
      ) {
        return (
          <Actions
            record={record}
            pageSize={pageSize}
            onOpenEditSponsoredArticleModal={
              handleOpenEditSponsoredArticleModal
            }
          />
        );
      },
    },
  ];

  const handleTabChange = (activeKey: ArticleTypes | string) => {
    history.push(
      `${ARTICLES}/${activeKey}/${DEFAULT_NUMBER_ITEMS_PER_PAGE}/${DEFAULT_CURRENT_PAGE}`
    );
  };

  const handleSetPagination = (page: number, size: number): void => {
    history.push(`${ARTICLES}/${articleType}/${size}/${page}`);
  };

  useEffect(() => {
    if (Number(itemsPerPage) !== pageSize) {
      setPageSize(Number(itemsPerPage));
    }
  }, [itemsPerPage, pageSize]);

  useEffect(() => {
    if (Number(pageNumber) !== currentPage) {
      setCurrentPage(Number(pageNumber));
    }
  }, [pageNumber, currentPage]);

  const renderTable = () => {
    // once we have more articles (like "organic") we will use different types and data
    return (
      <Table<GetArticles_getArticles_entities>
        columns={columns}
        data={data?.getArticles.entities}
        scroll={{ x: 300 }}
        loading={loading}
        total={data?.getArticles.total}
        defaultPageSize={pageSize}
        defaultCurrent={currentPage}
        setPageAndSize={handleSetPagination}
      />
    );
  };

  if (loading) {
    return <Spin />;
  }

  return (
    <Tabs activeKey={tabKey} onChange={handleTabChange}>
      <TabPane tab="Sponsored Articles" key={ArticleTypes.Sponsored}>
        <div className={styles.articles}>
          <Button
            className={styles.createBtn}
            type="primary"
            size="large"
            onClick={handleCreateArticle}
          >
            + create new article
          </Button>

          {renderTable()}
        </div>
        <CreateEditSponsoredArticles
          pageSize={pageSize}
          isVisible={!!updateModalVisibility || creationModalVisibility} //if either creation or edit is true, show this modal
          onClose={hideModal}
          record={updateModalVisibility} //if there is record, it is an edit modal-if there isn't, it's a creation modal
        />
        <Modal
          title="Article details"
          visible={!!detailsModalData}
          footer={null}
          onCancel={hideDetailsModal}
          width="70vw"
        >
          <SponsoredArticlesDetails record={detailsModalData} />
        </Modal>
      </TabPane>
    </Tabs>
  );
};

export default Articles;

import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Space } from 'antd';
// Hooks
import { useAppContext } from 'hooks';
// Types
import { UserRole } from 'api/graphql-global-types';
// Constants
import { DESIGN_REQUESTS, MY_DESIGN_REQUESTS } from 'constants/routes';
// Components
import DesignRequestDetailsComponent from 'components/common/DesignRequestsDetails/DesignRequestDetails/DesignRequestDetails';

const DesignRequestDetails: React.FC = () => {
  const history = useHistory();
  const { authUser } = useAppContext();

  const isDesigner = authUser?.role === UserRole.Designer;

  const handleGoBack = async () => {
    await history.push(isDesigner ? MY_DESIGN_REQUESTS : DESIGN_REQUESTS);
  };

  return (
    <>
      <Space direction="vertical" align="end">
        <Button type="primary" onClick={handleGoBack}>
          Back to design requests
        </Button>
      </Space>

      <DesignRequestDetailsComponent />
    </>
  );
};

export default DesignRequestDetails;

import { ContactType, TargetType } from 'api/graphql-global-types';
import { GetMediaPost_getMediaPost_VideoPost_mentions } from 'api/mediaPost/types/GetMediaPost';

export const formattedUnregisteredMention = (
  mention: {
    unregisteredContact: { name: any; representativeName: any; id: any };
    email: any;
    url: any;
  },
  type: ContactType
) => ({
  label:
    type === ContactType.ATHLETE
      ? mention?.unregisteredContact?.name || ''
      : mention?.unregisteredContact?.representativeName || '',
  value: mention?.unregisteredContact?.id || '',
  avatarURL: '',
  email: mention?.email || '',
  url: mention?.url || '',
});

export const reformattedUnregisteredMention = (
  option: { value: any; label: any; email: any; url: any },
  type: ContactType
) => ({
  id: option.value,
  store: null,
  mentionType: 'MEDIA_POST',
  targetType: TargetType.UNREGISTERED,
  unregisteredContact: {
    id: option.value,
    representativeName: type === ContactType.ATHLETE ? null : option.label,
    name: type === ContactType.BRAND ? null : option.label,
    type: type,
    email: option.email,
    url: option.url,
  },
});

export const formatMentionsInput = (
  mentions: GetMediaPost_getMediaPost_VideoPost_mentions[]
) => {
  return (
    mentions?.map((mention) => {
      if (mention.targetType === TargetType.REGISTERED) {
        return {
          storeId: mention?.store?.storeDetails?.storeId,
        };
      } else if (mention.targetType === TargetType.UNREGISTERED) {
        return {
          unregisteredContactId: mention?.unregisteredContact?.id,
        };
      }
      return null;
    }) || []
  );
};

import { gql } from '@apollo/client';

export const GET_ARTICLES = gql`
  query GetArticles($input: ArticleFilterInput!) {
    getArticles(input: $input) {
      limit
      offset
      total
      entities {
        articleUrl
        content
        createdAt
        hashtags {
          id
          name
        }
        id
        imageLabel
        key
        slug
        status
        store {
          id
          slug
          storeDetails {
            storeName
          }
        }
        title
        url
      }
    }
  }
`;

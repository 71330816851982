import React, { useCallback, useEffect, useState } from 'react';
import env from 'api/env';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Form, Input, Spin, Tooltip, Typography } from 'antd';
import {
  UpOutlined,
  DownOutlined,
  QuestionCircleTwoTone,
} from '@ant-design/icons';
// Helpers
import { getEnvLink } from 'helpers/getEnvLink';
import { uploadImages } from 'helpers/interview';
import { getProperErrorMessage } from 'helpers/errors';
import { formatMentionsInput } from 'helpers/mentions';
import { formatHashtagInput } from 'helpers/hashtags';
// Api
import { CREATE_UNREGISTERED_CONTACT } from 'api/mentions/mutations';
import { GET_INTERVIEW } from 'api/interview/queries';
import {
  CREATE_STREAM_IMAGE,
  UPDATE_INTERVIEW_INFO,
} from 'api/interview/mutations';
// Types
import {
  CreateStreamImage,
  CreateStreamImageVariables,
} from 'api/interview/types/CreateStreamImage';
import {
  UpdateInterviewInfo,
  UpdateInterviewInfoVariables,
} from 'api/interview/types/UpdateInterviewInfo';
import {
  GetInterview,
  GetInterviewVariables,
} from 'api/interview/types/GetInterview';
import {
  CreateMentionInput,
  CreateUnregisteredContactInput,
  InterviewStatus,
} from 'api/graphql-global-types';
import {
  CreateUnregisteredContact,
  CreateUnregisteredContactVariables,
} from 'api/mentions/types/CreateUnregisteredContact';
// UI
import { errorNotification, successNotification } from 'ui/Notification';
import CopyLink from 'ui/CopyLink/CopyLink';
// Components
import TagsInput from 'uiShared/TagsInput/TagsInput';
import Mentions, { MentionValues } from 'uiShared/Mentions/Mentions';
import CroppedPictureInput from 'components/common/CroppedPictureInput/CroppedPictureInput';
// Styles
import styles from './UpdateInfoModal.module.scss';

const { Text } = Typography;

type UpdateInfoModalProps = {
  interviewId: string;
  hideUpdateInfoModal: () => void;
};

const UpdateInfoModal = ({
  interviewId,
  hideUpdateInfoModal,
}: UpdateInfoModalProps): JSX.Element | null => {
  const [, setPictureValidation] = useState<string>('');
  const { data, loading } = useQuery<GetInterview, GetInterviewVariables>(
    GET_INTERVIEW,
    {
      variables: {
        input: {
          id: interviewId,
        },
      },
      skip: !interviewId,
      fetchPolicy: 'cache-and-network',
    }
  );

  const [createStreamImage] = useMutation<
    CreateStreamImage,
    CreateStreamImageVariables
  >(CREATE_STREAM_IMAGE);

  const [createUnregisteredContact] = useMutation<
    CreateUnregisteredContact,
    CreateUnregisteredContactVariables
  >(CREATE_UNREGISTERED_CONTACT);

  const [updateInterviewInfo, { loading: updateInterviewInfoLoading }] =
    useMutation<UpdateInterviewInfo, UpdateInterviewInfoVariables>(
      UPDATE_INTERVIEW_INFO,
      {
        refetchQueries: [
          {
            query: GET_INTERVIEW,
            variables: {
              input: {
                id: interviewId,
              },
            },
          },
        ],
      }
    );

  const interview = data?.getInterview || null;
  const isScheduled = data?.getInterview?.status === InterviewStatus.Scheduled;
  const isCompleted = data?.getInterview?.status === InterviewStatus.Completed;
  const isCanceled = data?.getInterview?.status === InterviewStatus.Canceled;
  const isCanceledOrCompleted = isCanceled || isCompleted;

  const [form] = Form.useForm();

  const [mentionsValues, setMentionsValues] = useState(
    interview?.stream?.mentions
  );
  const [hashtagValues, setHashtagValues] = useState(
    interview?.stream?.hashtags.map((tag) => tag.name) || []
  );

  const [isMentionsVisible, setIsMentionsVisible] = useState<boolean>(
    !!interview?.stream?.mentions.length || false
  );
  const toggleMentions = () => setIsMentionsVisible(!isMentionsVisible);

  const handleFormFinish = async (values: any) => {
    const shouldUpload = Boolean(Array.isArray(values.streamImage));
    if (shouldUpload) {
      const contentType = values?.streamImage[0]?.type;
      const ext = contentType?.split('/')[1];
      const interviewerId = interview?.interviewer?.id as string;
      try {
        if (ext && contentType && interviewerId) {
          const { data } = await createStreamImage({
            variables: {
              input: {
                files: [
                  {
                    contentType,
                    ext,
                  },
                ],
              },
            },
          });

          if (data) {
            const imagesUrls = await uploadImages(
              data?.createStreamImage,
              values.streamImage
            );

            const createUnregisteredContactInput =
              (mentionsValues as MentionValues[])?.filter(
                (mention) => !mention?.targetType
              ) || [];

            const unregisterContactsData = await createUnregisteredContact({
              variables: {
                input:
                  createUnregisteredContactInput as CreateUnregisteredContactInput[],
              },
            });

            const newUnregisteredContacts =
              (
                unregisterContactsData?.data?.createUnregisteredContact || []
              )?.map((contact) => ({
                unregisteredContactId: contact?.id,
              })) || [];

            const oldMentions: any =
              mentionsValues?.filter((mention) => mention?.targetType) || [];
            const formatedOldMentions = formatMentionsInput(oldMentions);

            await updateInterviewInfo({
              variables: {
                input: {
                  id: interviewId,
                  notes: form.getFieldValue('notes'),
                  scheduledMeetingLink: form.getFieldValue(
                    'scheduledMeetingLink'
                  ),
                  name: values.streamName ? values.streamName : null,
                  imageFileKey: imagesUrls[0],
                  hashtagInputs: hashtagValues?.length
                    ? formatHashtagInput(hashtagValues)
                    : null,
                  mentionsInputs: [
                    ...formatedOldMentions,
                    ...newUnregisteredContacts,
                  ] as CreateMentionInput[],
                },
              },
            });
            successNotification('Information updated successfully');
            hideUpdateInfoModal();
          }
        }
      } catch (err) {
        errorNotification(getProperErrorMessage(err, 'Something went wrong'));
      }
    } else {
      try {
        const createUnregisteredContactInput =
          (mentionsValues as MentionValues[])?.filter(
            (mention) => !mention?.targetType
          ) || [];

        const unregisterContactsData = await createUnregisteredContact({
          variables: {
            input:
              createUnregisteredContactInput as CreateUnregisteredContactInput[],
          },
        });

        const newUnregisteredContacts =
          (unregisterContactsData?.data?.createUnregisteredContact || [])?.map(
            (contact) => ({
              unregisteredContactId: contact?.id,
            })
          ) || [];

        const oldMentions: any =
          mentionsValues?.filter((mention) => mention?.targetType) || [];
        const formatedOldMentions = formatMentionsInput(oldMentions);

        await updateInterviewInfo({
          variables: {
            input: {
              id: interviewId,
              notes: form.getFieldValue('notes'),
              scheduledMeetingLink: form.getFieldValue('scheduledMeetingLink'),
              name: values.streamName ? values.streamName : null,
              hashtagInputs: hashtagValues?.length
                ? formatHashtagInput(hashtagValues)
                : null,
              mentionsInputs: [
                ...formatedOldMentions,
                ...newUnregisteredContacts,
              ] as CreateMentionInput[],
            },
          },
        });

        successNotification('Information updated successfully');
        hideUpdateInfoModal();
      } catch (err) {
        errorNotification(getProperErrorMessage(err, 'Something went wrong'));
      }
    }
  };

  const handleStartStream = () => {
    window.open(
      `${getEnvLink(env.REACT_APP_ENV)}/${
        interview?.interviewer?.slug
      }/start-stream/${interview?.stream?.slug}`,
      '_blank'
    );
  };

  useEffect(() => {
    if (interview) {
      form.setFieldsValue({
        notes: interview.notes,
        scheduledMeetingLink: interview.scheduledMeetingLink,
        streamName: interview.stream?.name,
        streamImage: interview.stream?.mainImageUrl,
      });
      setHashtagValues(
        interview?.stream?.hashtags.map((tag) => tag.name) || []
      );
      setMentionsValues(interview.stream?.mentions);
      setIsMentionsVisible(!!interview.stream?.mentions.length);
    }
  }, [interview, form]);

  const handleMentionChange = useCallback(
    (mentions) => {
      if (JSON.stringify(mentions) !== JSON.stringify(mentionsValues)) {
        setMentionsValues(mentions);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mentionsValues]
  );

  const handleHashtagsChange = (value: any) => {
    setHashtagValues(value);
    form.setFieldsValue({ hashtags: value });
  };

  if (loading || updateInterviewInfoLoading) {
    return <Spin />;
  }

  if (!data) {
    return null;
  }

  return (
    <div className={styles.interviewRoot}>
      <Form
        form={form}
        layout="horizontal"
        name="sendInviteForm"
        autoComplete="off"
        onFinish={handleFormFinish}
      >
        <div className={styles.interviewContainer}>
          <div className={styles.interviewLeftSide}>
            <div>
              <p>
                <strong>Task ID:</strong> {interview?.id || ''}
              </p>
            </div>

            <div>
              <p>
                <strong>Account: </strong>
                <a
                  href={`${getEnvLink(env.REACT_APP_ENV)}/${
                    interview?.interviewee?.slug || ''
                  }`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {interview?.interviewee?.firstName || ''}{' '}
                  {interview?.interviewee?.lastName || ''}
                </a>
              </p>
            </div>

            <div>
              <p>
                <strong>Status:</strong> {interview?.status || ''}
              </p>
            </div>

            {!isCanceledOrCompleted && (
              <CopyLink
                name="schedule-url"
                label="Schedule interview link"
                link={`${getEnvLink(env.REACT_APP_ENV)}/schedule-interview/${
                  interview?.id
                }`}
              />
            )}

            {isScheduled && (
              <>
                {interview?.stream?.slug && (
                  <>
                    <CopyLink
                      name="stream-url"
                      label="Start Stream link"
                      link={`${getEnvLink(env.REACT_APP_ENV)}/${
                        interview?.interviewer?.slug
                      }/start-stream/${interview?.stream?.slug}`}
                    />

                    <Button onClick={handleStartStream} danger>
                      Start interview
                    </Button>
                  </>
                )}

                {interview?.streamCohostToken && (
                  <CopyLink
                    name="guest"
                    label="Link for guest to join"
                    link={`${getEnvLink(env.REACT_APP_ENV)}/guest/${
                      interview?.streamCohostToken
                    }`}
                  />
                )}
              </>
            )}

            <div>
              <p>
                <strong>Admin notes:</strong> {interview?.adminNotes || ''}
              </p>
            </div>

            <Form.Item name="notes" label={<Text>My Notes</Text>}>
              <Input placeholder="Add notes here" />
            </Form.Item>

            <Form.Item name="hashtags" label="Tagging">
              <TagsInput
                hashtags={hashtagValues}
                onChange={(value: any) =>
                  handleHashtagsChange(value as string[])
                }
                name="hashtags"
                disabled={!interview?.stream}
              />
            </Form.Item>

            {interview?.status === InterviewStatus.Invited ||
            interview?.status === InterviewStatus.InviteViewed ? (
              <></>
            ) : (
              <>
                <Form.Item name="streamName" label={<Text>Stream Title</Text>}>
                  <Input />
                </Form.Item>

                <Form.Item name="streamImage" label="Stream Cover Image">
                  <CroppedPictureInput
                    setPictureValidation={setPictureValidation}
                    defaultImage={interview?.stream?.mainImageUrl || null}
                    buttonTitle="Upload stream cover image"
                  />
                </Form.Item>
              </>
            )}
          </div>

          <div className={styles.interviewRightSide}>
            <Form.Item className={styles.mentionsSectionRoot}>
              <Text className={styles.mentionSectionTitleWrapper}>
                <p className={styles.mentionsSectionTitle}>
                  Mentioning options
                </p>
                <Tooltip
                  title="Mention athletes, brands, and organizations by using their names and
          links to directly engage them and increase interaction on your
          content. Write down their names and url links."
                >
                  <QuestionCircleTwoTone />
                </Tooltip>
              </Text>

              <div className={styles.mentionsSection}>
                <Button
                  onClick={toggleMentions}
                  color="white"
                  className={styles.arrowDownIconButton}
                  disabled={!interview?.stream}
                >
                  {isMentionsVisible ? (
                    <UpOutlined className={styles.arrowDownIcon} />
                  ) : (
                    <DownOutlined className={styles.arrowDownIcon} />
                  )}
                </Button>
              </div>
            </Form.Item>
            <div className={styles.mentionsContainer}>
              {isMentionsVisible && (
                <Mentions
                  mentions={mentionsValues || interview?.stream?.mentions || []}
                  onChange={handleMentionChange}
                />
              )}
            </div>
          </div>
        </div>
        <div className={styles.saveButtonContainer}>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={updateInterviewInfoLoading}
            >
              Save
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default UpdateInfoModal;

import { gql } from '@apollo/client';
import { VideoEditorFields } from './fragments';

export const GET_VIDEO_EDITORS = gql`
  query GetVideoEditors($input: VideoEditorFilterInput!) {
    getVideoEditors(input: $input) {
      entities {
        ...VideoEditorFields
      }
      total
      offset
      limit
    }
  }
  ${VideoEditorFields}
`;

export const GET_VIDEO_EDITOR = gql`
  query GetVideoEditor($id: String) {
    getVideoEditor(id: $id) {
      ...VideoEditorFields
    }
  }
  ${VideoEditorFields}
`;

export const GET_VIDEO_EDIT_REQUESTS = gql`
  query GetVideoEditRequests($input: VideoEditRequestsFilterInput!) {
    getVideoEditRequests(input: $input) {
      entities {
        adminNotes
        additionalStores {
          id
          email
          firstName
          lastName
          status
          storeDetails {
            id
            storeName
          }
        }
        auditLogs {
          action
          assignedEditorId
          createdAt
          details {
            key
            value
          }
          feedback
          id
          oldAssignedEditorId
          performedBy {
            role
            id
            firstName
            lastName
            email
            slug
          }
          performedById
          type
        }
        comments {
          id
          feedback
          type
          createdAt
        }
        assignedEditor {
          createdAt
          email
          firstName
          id
          interviewerDetails {
            id
            isIncludedInAutoAssignment
          }
          lastName
          phoneNumber
          role
          slug
          status
          storeDetails {
            id
            storeName
          }
        }
        assignedEditorId
        createdAt
        dueDate
        editedVideos {
          id
          key
          videoEditRequestId
          videoUrl
          thumbnailKey
          thumbnailUrl
          title
          body
          reviewSubmissions {
            id
            store {
              id
              storeDetails {
                storeName
              }
            }
            status
            rejections {
              reason
            }
            feedback
            assignedToStoreAt
          }
        }
        id
        notes
        rate
        rawUrls
        status
        store {
          createdAt
          email
          firstName
          id
          isOnboarded
          lastName
          phoneNumber
          role
          slug
          status
          storeDetails {
            id
            storeName
          }
        }
        storeId
        videoSource {
          id
          type
          ... on VideoEditStreamSource {
            stream {
              id
              slug
              store {
                slug
              }
            }
          }
          ... on VideoEditInterviewSource {
            interview {
              id
              creationType
              interviewer {
                slug
              }
              source {
                type
              }
              stream {
                id
                slug
                streamRecords {
                  downloadVideoURL
                }
              }
            }
          }
          ... on VideoEditMediaPostSource {
            id
            mediaPost {
              id
              slug
              store {
                slug
              }
              video {
                url
                thumbnailUrl
              }
            }
          }
        }
      }
      counters {
        pastDueDateCount
        unassignedCount
        inProgressCount
        completedCount
      }
      total
      offset
      limit
    }
  }
`;

export const GET_VIDEO_EDIT_REQUESTS_FOR_EDITORS = gql`
  query GetVideoEditRequestsForEditors($input: VideoEditRequestsFilterInput!) {
    getVideoEditRequests(input: $input) {
      entities {
        adminNotes
        additionalStores {
          id
          email
          firstName
          lastName
          status
          storeDetails {
            id
            storeName
          }
        }
        assignedEditor {
          createdAt
          email
          firstName
          id
          interviewerDetails {
            id
            isIncludedInAutoAssignment
          }
          lastName
          phoneNumber
          role
          slug
          status
          storeDetails {
            id
            storeName
          }
        }
        assignedEditorId
        comments {
          id
          feedback
          type
          createdAt
        }
        createdAt
        dueDate
        editedVideos {
          id
          key
          videoEditRequestId
          videoUrl
          thumbnailKey
          thumbnailUrl
          title
          body
          reviewSubmissions {
            id
            store {
              id
              storeDetails {
                storeName
              }
            }
            status
            rejections {
              reason
            }
            feedback
            assignedToStoreAt
          }
        }
        id
        notes
        rate
        rawUrls
        status
        store {
          createdAt
          email
          firstName
          id
          isOnboarded
          lastName
          phoneNumber
          role
          slug
          status
          storeDetails {
            id
            storeName
          }
        }
        storeId
        videoSource {
          id
          type
          ... on VideoEditStreamSource {
            stream {
              id
              slug
              store {
                slug
              }
            }
          }
          ... on VideoEditInterviewSource {
            interview {
              id
              creationType
              source {
                type
              }
              interviewer {
                slug
              }
              stream {
                id
                slug
                streamRecords {
                  downloadVideoURL
                }
              }
            }
          }
          ... on VideoEditMediaPostSource {
            id
            mediaPost {
              id
              slug
              store {
                slug
              }
              video {
                url
                thumbnailUrl
              }
            }
          }
        }
      }
      counters {
        pastDueDateCount
        unassignedCount
      }
      total
      offset
      limit
    }
  }
`;

export const GET_VIDEO_EDIT_REQUEST_SETTINGS = gql`
  query GetVideoEditRequestSettings {
    getVideoEditRequestSettings {
      id
      ... on InterviewVideoEditRequestTrigger {
        id
        isEnabled
      }
      ... on StreamVideoEditRequestTrigger {
        id
        isEnabled
        config {
          duration
        }
      }
      ... on MediaPostVideoEditRequestTrigger {
        id
        isEnabled
        config {
          duration
        }
      }
    }
  }
`;

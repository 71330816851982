import { gql } from '@apollo/client';

export const SET_BRAND_DEAL_SETTINGS = gql`
  mutation SetBrandDealSettings($input: SetBrandDealSettingsInput!) {
    setBrandDealSettings(input: $input) {
      id
      contactEmail
      minFlatPrice
    }
  }
`;

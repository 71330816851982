import React, { useEffect } from 'react';
import { Form, Button, InputNumber, Input, Typography } from 'antd';
import { useMutation, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
// Hooks
import { useDefaultStoreRedirectOnEmptyId } from 'hooks';
// Api
import { SET_BRAND_DEAL_SETTINGS } from 'api/deals/mutations';
import { GET_STORE_BRAND_DEAL_INFO } from 'api/store/queries';
// Types
import {
  SetBrandDealSettings,
  SetBrandDealSettingsVariables,
} from 'api/deals/types/SetBrandDealSettings';
import { SetBrandDealSettingsInput } from 'api/graphql-global-types';
import {
  GetStoreBrandDeal,
  GetStoreBrandDealVariables,
} from 'api/store/types/GetStoreBrandDeal';
// UI
import { errorNotification, successNotification } from 'ui/Notification';
// Styles
import styles from './SetupDeals.module.scss';

type FormValues = {
  contactEmail?: string | null;
  minFlatPrice?: number;
};

const { Text } = Typography;

const SetupDeals = (): JSX.Element => {
  useDefaultStoreRedirectOnEmptyId();
  const { storeId } = useParams<{ storeId: string | undefined }>();

  const [form] = Form.useForm();

  const { data, refetch } = useQuery<
    GetStoreBrandDeal,
    GetStoreBrandDealVariables
  >(GET_STORE_BRAND_DEAL_INFO, {
    variables: {
      input: {
        id: storeId,
      },
    },
    fetchPolicy: 'cache-and-network',
    skip: !storeId,
  });

  const [setBrandDealSettings, { loading }] = useMutation<
    SetBrandDealSettings,
    SetBrandDealSettingsVariables
  >(SET_BRAND_DEAL_SETTINGS);

  const storeData = data?.store;

  const initialFormValues: FormValues = {
    contactEmail:
      storeData?.brandDealSettings?.contactEmail || storeData?.email || '',
    minFlatPrice: (storeData?.brandDealSettings?.minFlatPrice as number) || 0,
  };

  const onSubmit = async () => {
    const { contactEmail, minFlatPrice } = form.getFieldsValue();

    const brandDealSettingsInput: SetBrandDealSettingsInput = {
      contactEmail: contactEmail || null,
      minFlatPrice: minFlatPrice || null,
      ownerId: storeId,
    };

    try {
      await setBrandDealSettings({
        variables: {
          input: brandDealSettingsInput,
        },
      });
      successNotification(`Brand Deal Settings updated successfully`);
      refetch({
        input: {
          id: storeId,
        },
      });
    } catch (error) {
      errorNotification((error as Error)?.message);
      console.error(error);
    }
  };

  useEffect(() => {
    if (storeData) {
      form.setFieldsValue({
        contactEmail:
          storeData.brandDealSettings?.contactEmail || storeData.email || '',
      });
      form.setFieldsValue({
        minFlatPrice: storeData.brandDealSettings?.minFlatPrice || 0,
      });
    }
  }, [storeData, form, data]);

  return (
    <div className={styles.root}>
      <Form
        name="SetupDealsForm"
        aria-label="Setup Deals Form"
        onFinish={onSubmit}
        form={form}
        initialValues={initialFormValues}
      >
        <Form.Item
          name="contactEmail"
          label={<Text>Preferred Email</Text>}
          rules={[
            {
              required: false,
            },
            {
              type: 'email',
              message: 'Please input a valid email address',
            },
          ]}
        >
          <Input
            type="email"
            placeholder="Preferred Email"
            aria-label="Preferred email"
          />
        </Form.Item>
        <Form.Item
          name="minFlatPrice"
          label={<Text>Minimum Deal Price</Text>}
          rules={[
            {
              required: false,
            },
            {
              type: 'number',
              message: 'Please enter the minimum deal price',
            },
          ]}
        >
          <InputNumber
            type="number"
            placeholder="Minimum Deal Price"
            aria-label="Minimum deal price"
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default SetupDeals;

import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useSetState } from 'react-use';
import { useParams } from 'react-router-dom';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { useQuery, useMutation } from '@apollo/client';
import { isMobile } from 'react-device-detect';
import moment, { Moment } from 'moment';
import momentTimezone from 'moment-timezone';
import cn from 'classnames';
import { DatePicker, Select, Checkbox } from 'antd';
import { UpOutlined, DownOutlined } from '@ant-design/icons';
// Api
import { GET_MEDIA_POST, GET_MEDIA_POSTS } from 'api/mediaPost/queries';
import {
  CREATE_IMAGE_POST_ADMIN,
  EDIT_IMAGE_POST_ADMIN,
  CREATE_VIDEO_POST_ADMIN,
  EDIT_VIDEO_POST_ADMIN,
  DELETE_MEDIA_POST_ADMIN,
  COMPLETE_POST_VIDEO_MULTIPART_UPLOAD,
  ADMIN_INIT_POST_VIDEO_MULTIPART_UPLOAD_V2,
  ADMIN_CREATE_POST_IMAGE_PRESIGNED_URL_V2,
} from 'api/mediaPost/mutations';
import { CREATE_UNREGISTERED_CONTACT } from 'api/mentions/mutations';
// Types
import {
  CreateImagePostAdmin,
  CreateImagePostAdminVariables,
} from 'api/mediaPost/types/CreateImagePostAdmin';
import {
  EditImagePostAdmin,
  EditImagePostAdminVariables,
} from 'api/mediaPost/types/EditImagePostAdmin';
import {
  EditVideoPostAdmin,
  EditVideoPostAdminVariables,
} from 'api/mediaPost/types/EditVideoPostAdmin';
import {
  DeleteMediaPostAdmin,
  DeleteMediaPostAdminVariables,
} from 'api/mediaPost/types/DeleteMediaPostAdmin';
import {
  CreateVideoPostAdmin,
  CreateVideoPostAdminVariables,
} from 'api/mediaPost/types/CreateVideoPostAdmin';
import {
  CreateImagePostAdminInput,
  CreateMentionInput,
  CreateUnregisteredContactInput,
  CreateVideoPostAdminInput,
  EditImagePostAdminInput,
  EditVideoPostAdminInput,
  MediaJobStatus,
  MediaPostStatus,
} from 'api/graphql-global-types';
import {
  GetMediaPost,
  GetMediaPost_getMediaPost_VideoPost_mentions,
  GetMediaPostVariables,
} from 'api/mediaPost/types/GetMediaPost';
import {
  GetMediaPost_getMediaPost_VideoPost,
  GetMediaPost_getMediaPost_ImagePost,
} from 'api/mediaPost/types/GetMediaPost';
import {
  AdminCreatePostImagePresignedUrlV2,
  AdminCreatePostImagePresignedUrlV2Variables,
} from 'api/mediaPost/types/AdminCreatePostImagePresignedUrlV2';
import {
  AdminInitPostVideoMultipartUploadV2,
  AdminInitPostVideoMultipartUploadV2Variables,
} from 'api/mediaPost/types/AdminInitPostVideoMultipartUploadV2';
import {
  CompletePostVideoMultipartUpload,
  CompletePostVideoMultipartUploadVariables,
} from 'api/mediaPost/types/CompletePostVideoMultipartUpload';
import {
  CreateUnregisteredContact,
  CreateUnregisteredContactVariables,
} from 'api/mentions/types/CreateUnregisteredContact';
// Helpers
import { getProperErrorMessage } from 'helpers/errors';
import { formatMentionsInput } from 'helpers/mentions';
import { bytesToSize } from 'helpers/bytesToSize';
import { getIfVideoSupportedByBrowser } from 'helpers/media';
import {
  calculateChunkSize,
  getValidationSchema,
  uploadImageToS3,
  MEDIA_TYPE,
} from 'helpers/mediaFeed';
import { Uploader } from 'helpers/uploader';
import {
  convertFromUtc,
  convertToUtcDate,
  getMediaPostTimeZoneObject,
  getTimeZoneLabel,
  getTimeZoneName,
} from 'helpers/timeZone';
import { formatHashtagInput } from 'helpers/hashtags';
// Constants
import { TIME_ZONE_OPTIONS } from 'constants/timeZone';
// Components
import { commonTimeFormat } from 'constants/global';
import EasyImageCrop from 'components/common/EasyImageCrop/EasyImageCrop';
import UploadImagePlaceholder from 'components/common/UploadImagePlaceholder/UploadImagePlaceholder';
// UI
import { successNotification, errorNotification } from 'ui/Notification';
import { DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import TagsInput from 'uiShared/TagsInput/TagsInput';
// UiShared
import Button from 'uiShared/Button/Button';
import TextArea from 'uiShared/Textarea/Textarea';
import RadioGroup from 'uiShared/RadioGroup/RadioGroup';
import VideoPreview from 'uiShared/VideoPreview/VideoPreview';
import Mentions, { MentionValues } from 'uiShared/Mentions/Mentions';
// Styles
import styles from './CreateEditMediaFeed.module.scss';

const { Option } = Select;

const MAX_VIDEO_SIZE_B = 1024 * 1024 * 1024 * 1024; // equals to 1TB, our current limit

type MediaFeedProps = {
  mediaFeed?:
    | GetMediaPost_getMediaPost_VideoPost
    | GetMediaPost_getMediaPost_ImagePost;
  onComplete: () => void;
  onFailure?: () => void;
};

type FormInputs = {
  title: string;
  body?: string | null;
  hashtags?: string[];
  image?: File | null;
  video?: string | null;
  previewImage?: string;
  scheduled: boolean;
  scheduledDate?: string | Date | null;
  tzCode?: string | null;
};

const CreateEditMediaFeedStream = ({
  mediaFeed,
  onComplete,
  onFailure,
}: MediaFeedProps): JSX.Element => {
  const { storeId } = useParams<{ storeId: string }>();

  const uploadVideoRef = useRef<HTMLInputElement | null>(null);
  const imageUploadRef = useRef<HTMLInputElement>(null);

  const [isVideo, setIsVideo] = useState<boolean>(
    (mediaFeed && 'video' in mediaFeed) || false
  );
  const [isMentionsVisible, setIsMentionsVisible] = useState<boolean>(
    !!mediaFeed?.mentions.length || false
  );

  const [mentionsValues, setMentionsValues] = useState<MentionValues[]>(
    mediaFeed?.mentions || []
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [videoProgressContainer, setVideoProgressContainer] =
    useState<string>('');
  const [videoId, setVideoId] = useState<string>('');
  const [scheduled, setScheduled] = useState<boolean>(
    !!mediaFeed?.scheduledAt || false
  );

  const initialVideoStateValues = {
    videoFile: null,
    videoPreviewURL:
      mediaFeed && 'video' in mediaFeed ? mediaFeed?.video?.url : null,
    cropArea: null,
    isScrollDisabled: false,
    videoFileSize: 0,
    showVideoPreview: (mediaFeed && 'video' in mediaFeed) || false,
    isCropNotSupported: false,
    isCropVisible: false,
  };

  const [state, setState] = useSetState<{
    videoFile: File | null;
    videoPreviewURL: string | null;
    isScrollDisabled: boolean;
    videoFileSize: number;
    showVideoPreview: boolean;
  }>(initialVideoStateValues);

  const { videoFile, videoPreviewURL, videoFileSize, showVideoPreview } = state;

  const isEdit = !!mediaFeed;
  const mediaFeedId = mediaFeed?.id || '';
  const fileId =
    mediaFeed && 'video' in mediaFeed
      ? mediaFeed.video.id
      : mediaFeed?.image.id || '';

  const changeType = (value: string) => {
    if (!isEdit) {
      setIsVideo(value === 'video' ? true : false);
      setValue('image', null);
      setValue('timezone', null);
      setValue('hashtags', []);
      setValue('scheduledDate', null);
      setValue('scheduled', false);
      setState(initialVideoStateValues);
    }
  };

  const isScheduled = mediaFeed?.status === MediaPostStatus.scheduled;
  const isPublished = mediaFeed?.status === MediaPostStatus.published;
  const toggleMentions = () => setIsMentionsVisible(!isMentionsVisible);

  const {
    register,
    handleSubmit,
    reset,
    control,
    watch,
    setValue,
    unregister,
    formState: { errors },
  } = useForm<FormInputs>({
    resolver: yupResolver(getValidationSchema({ isVideo })),
    mode: 'onChange',
    defaultValues: {
      title: (mediaFeed && mediaFeed.title) || '',
      body: (mediaFeed && mediaFeed.body) || '',
      hashtags: (mediaFeed && mediaFeed.hashtags.map((tag) => tag.name)) || [],
      previewImage:
        mediaFeed && 'video' in mediaFeed
          ? mediaFeed.thumbnailUrl || ''
          : mediaFeed?.image.url || '',
      scheduled: isScheduled || false,
      scheduledDate: mediaFeed?.scheduledAt
        ? convertFromUtc(
            mediaFeed?.scheduledAt,
            mediaFeed?.scheduledAtOffset || 0
          )
        : null,
      tzCode: getMediaPostTimeZoneObject(mediaFeed),
    },
  });

  const image = watch('image');
  const previewImage = watch('previewImage');
  const tzCode = watch('tzCode');
  const hashtags = watch('hashtags');

  const refetchMediaPostsObject = {
    refetchQueries: [
      {
        query: GET_MEDIA_POSTS,
        variables: {
          input: { storeId },
        },
      },
    ],
  };

  const [adminCreatePostImagePresignedUrlV2] = useMutation<
    AdminCreatePostImagePresignedUrlV2,
    AdminCreatePostImagePresignedUrlV2Variables
  >(ADMIN_CREATE_POST_IMAGE_PRESIGNED_URL_V2);

  const [createImagePost] = useMutation<
    CreateImagePostAdmin,
    CreateImagePostAdminVariables
  >(CREATE_IMAGE_POST_ADMIN, refetchMediaPostsObject);

  const [editImagePost] = useMutation<
    EditImagePostAdmin,
    EditImagePostAdminVariables
  >(EDIT_IMAGE_POST_ADMIN, refetchMediaPostsObject);

  const [createUnregisteredContact] = useMutation<
    CreateUnregisteredContact,
    CreateUnregisteredContactVariables
  >(CREATE_UNREGISTERED_CONTACT);

  const [adminInitPostVideoMultipartUploadV2] = useMutation<
    AdminInitPostVideoMultipartUploadV2,
    AdminInitPostVideoMultipartUploadV2Variables
  >(ADMIN_INIT_POST_VIDEO_MULTIPART_UPLOAD_V2);

  const [completePostVideoMultipartUpload] = useMutation<
    CompletePostVideoMultipartUpload,
    CompletePostVideoMultipartUploadVariables
  >(COMPLETE_POST_VIDEO_MULTIPART_UPLOAD);

  const [createVideoPost] = useMutation<
    CreateVideoPostAdmin,
    CreateVideoPostAdminVariables
  >(CREATE_VIDEO_POST_ADMIN, refetchMediaPostsObject);

  const [editVideoPost] = useMutation<
    EditVideoPostAdmin,
    EditVideoPostAdminVariables
  >(EDIT_VIDEO_POST_ADMIN, refetchMediaPostsObject);

  const [deleteMediaPost] = useMutation<
    DeleteMediaPostAdmin,
    DeleteMediaPostAdminVariables
  >(DELETE_MEDIA_POST_ADMIN, refetchMediaPostsObject);

  const { data, stopPolling, startPolling } = useQuery<
    GetMediaPost,
    GetMediaPostVariables
  >(GET_MEDIA_POST, {
    variables: {
      input: { id: videoId },
    },
    skip: !videoId,
    pollInterval: 2000,
  });

  useEffect(() => {
    if (data?.getMediaPost && 'video' in data.getMediaPost) {
      const convertStatus = data.getMediaPost.video.jobStatus;
      const convertProgress = data.getMediaPost.video.jobProgress;
      handleReturnConvertingPercentage(convertProgress || 0);

      if (convertStatus === MediaJobStatus.COMPLETED) {
        stopPolling();
        setIsLoading(false);
        setVideoProgressContainer('');
        successNotification("You've created your video post!");
        handleOnComplete();
      }

      if (convertStatus === MediaJobStatus.FAILED) {
        stopPolling();
        setIsLoading(false);
        setVideoProgressContainer('');
        errorNotification('Conversion failed, please change video file format');
        onFailure && onFailure();
      }

      if (convertStatus === MediaJobStatus.PROCESSING) {
        startPolling(2000);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.getMediaPost]);

  const handleReturnUploadPercentage = (percentage: string) => {
    setVideoProgressContainer('Uploading: ' + percentage);
  };

  const handleReturnConvertingPercentage = (percentage: string | number) => {
    if (percentage) {
      setVideoProgressContainer('Converting: ' + percentage + '%');
    } else {
      setVideoProgressContainer('Converting');
    }
  };

  const handleOnComplete = () => {
    reset();
    setIsLoading(false);
    onComplete();
  };

  const handleUploadVideoError = (err: Error) => {
    getProperErrorMessage(err, 'Error uploading video!');
  };

  const selectedTimeZoneOffset = momentTimezone(moment().toDate())
    .tz(getTimeZoneName(tzCode))
    .utcOffset();

  const handleImageSubmit = async (values: FormInputs) => {
    const uploadData = await uploadImageStep();

    const createUnregisteredContactInput =
      (mentionsValues as MentionValues[])?.filter(
        (mention) => !mention?.targetType
      ) || [];

    const unregisterContactsData = await createUnregisteredContact({
      variables: {
        input:
          createUnregisteredContactInput as CreateUnregisteredContactInput[],
      },
    });

    const newUnregisteredContacts =
      (unregisterContactsData?.data?.createUnregisteredContact || [])?.map(
        (contact) => ({
          unregisteredContactId: contact?.id,
        })
      ) || [];

    const oldMentions = (mentionsValues?.filter(
      (mention) => mention?.targetType
    ) || []) as GetMediaPost_getMediaPost_VideoPost_mentions[];
    const formatedOldMentions = formatMentionsInput(oldMentions);

    if (uploadData) {
      const createImagePostInput: CreateImagePostAdminInput = {
        storeId,
        title: values.title,
        hashtagInputs: values?.hashtags?.length
          ? formatHashtagInput(values.hashtags)
          : null,
        image: { key: uploadData },
        mentionsInputs: [
          ...formatedOldMentions,
          ...newUnregisteredContacts,
        ] as CreateMentionInput[],
      };

      if (values.scheduledDate) {
        createImagePostInput.scheduled = {
          at: convertToUtcDate(values.scheduledDate, selectedTimeZoneOffset),
          offset: selectedTimeZoneOffset,
          tzCode: values.tzCode,
        };
      }

      try {
        await createImagePost({
          variables: {
            input: createImagePostInput,
          },
        });
        successNotification("You've created your image post!");

        handleOnComplete();
      } catch (err) {
        setIsLoading(false);
        errorNotification(
          getProperErrorMessage(err, 'Error creating image post!')
        );
      }
    }
  };

  const handleMentionChange = useCallback(
    (mentions) => {
      if (JSON.stringify(mentions) !== JSON.stringify(mentionsValues)) {
        return setMentionsValues(mentions);
      }
    },
    [mentionsValues]
  );
  const handleVideoSubmit = async (values: FormInputs) => {
    const videoFileName = videoFile?.name;

    const imageKey = await uploadImageStep();

    if (videoFile) {
      try {
        const chunkSize = calculateChunkSize((videoFile as File).size);
        const contentType = (videoFile as File).type;
        const numberOfParts = Math.ceil(videoFile.size / chunkSize);
        const fileExtension = (videoFile as File).name.split('.').pop() || '';

        const { data } = await adminInitPostVideoMultipartUploadV2({
          variables: {
            input: {
              contentType,
              fileExt: fileExtension,
              partCnt: numberOfParts,
              storeId,
            },
          },
        });

        if (data?.adminInitPostVideoMultipartUploadV2) {
          let fileKey: string;

          const uploader = new Uploader({
            chunkSize: chunkSize,
            file: new File([videoFile], `${videoFileName}.${fileExtension}`),
            initData: data.adminInitPostVideoMultipartUploadV2,
          });

          uploader.start();

          uploader
            .onProgress(({ percentage: newPercentage }) => {
              handleReturnUploadPercentage(`${newPercentage}%`);
            })
            .onComplete(async (input) => {
              fileKey = input.fileKey;

              await completePostVideoMultipartUpload({
                variables: {
                  input,
                },
              });

              const createUnregisteredContactInput =
                (mentionsValues as MentionValues[])?.filter(
                  (mention) => !mention?.targetType
                ) || [];

              const unregisterContactsData = await createUnregisteredContact({
                variables: {
                  input:
                    createUnregisteredContactInput as CreateUnregisteredContactInput[],
                },
              });

              const newUnregisteredContacts =
                (
                  unregisterContactsData?.data?.createUnregisteredContact || []
                )?.map((contact) => ({
                  unregisteredContactId: contact?.id,
                })) || [];

              const oldMentions = (mentionsValues?.filter(
                (mention) => mention?.targetType
              ) || []) as GetMediaPost_getMediaPost_VideoPost_mentions[];
              const formatedOldMentions = formatMentionsInput(oldMentions);

              const createVideoPostInput: CreateVideoPostAdminInput = {
                storeId,
                title: values.title,
                body: values.body,
                hashtagInputs: values?.hashtags?.length
                  ? formatHashtagInput(values.hashtags)
                  : null,
                mentionsInputs: [
                  ...formatedOldMentions,
                  ...newUnregisteredContacts,
                ] as CreateMentionInput[],
                video: { key: fileKey || '' },
              };

              if (values.scheduledDate) {
                createVideoPostInput.scheduled = {
                  at: convertToUtcDate(
                    values.scheduledDate,
                    selectedTimeZoneOffset
                  ),
                  offset: selectedTimeZoneOffset,
                  tzCode: values.tzCode,
                };
              }

              if (imageKey) {
                createVideoPostInput.thumbnailKey = imageKey;
              }

              const createdPost = await createVideoPost({
                variables: {
                  input: createVideoPostInput,
                },
              });

              if (createdPost) {
                setVideoId(createdPost.data?.createVideoPostAdmin.id || '');
                successNotification(
                  'Your video is uploaded, starting conversion now'
                );
              }
            })
            .onError(handleUploadVideoError);
        }
      } catch (error) {
        setIsLoading(false);
        handleUploadVideoError(error as unknown as Error);
      }
    }
  };
  const onEditFinish = () => {
    window.location.reload();
  };

  const handleImageEdit = async (values: FormInputs) => {
    const uploadData = await uploadImageStep();

    const createUnregisteredContactInput =
      (mentionsValues as MentionValues[])?.filter(
        (mention) => !mention?.targetType
      ) || [];

    const unregisterContactsData = await createUnregisteredContact({
      variables: {
        input:
          createUnregisteredContactInput as CreateUnregisteredContactInput[],
      },
    });

    const newUnregisteredContacts =
      (unregisterContactsData?.data?.createUnregisteredContact || [])?.map(
        (contact) => ({
          unregisteredContactId: contact?.id,
        })
      ) || [];

    const oldMentions = (mentionsValues?.filter(
      (mention) => mention?.targetType
    ) || []) as GetMediaPost_getMediaPost_VideoPost_mentions[];
    const formatedOldMentions = formatMentionsInput(oldMentions);

    if (uploadData) {
      const editImagePostInput: EditImagePostAdminInput = {
        storeId,
        id: mediaFeedId,
        title: values.title,
        hashtagInputs: values?.hashtags?.length
          ? formatHashtagInput(values.hashtags)
          : null,
        mentionsInputs: [
          ...formatedOldMentions,
          ...newUnregisteredContacts,
        ] as CreateMentionInput[],
        image: { key: uploadData },
      };

      if (values.scheduledDate && !isPublished) {
        editImagePostInput.scheduled = {
          at: convertToUtcDate(values.scheduledDate, selectedTimeZoneOffset),
          offset: selectedTimeZoneOffset,
          tzCode: values?.tzCode,
        };
      }
      try {
        const newPost = await editImagePost({
          variables: {
            input: editImagePostInput,
          },
        });
        successNotification("You've edited your image post!");

        handleOnComplete();
        if (newPost.data) {
          onEditFinish();
        }
      } catch (error) {
        setIsLoading(false);
        errorNotification(getProperErrorMessage(error, 'Error editing image!'));
      }
    }
    try {
      const editImagePostInput: EditImagePostAdminInput = {
        storeId,
        id: mediaFeedId,
        title: values.title,
        body: values.body,
        hashtagInputs: values?.hashtags?.length
          ? formatHashtagInput(values.hashtags)
          : null,
        mentionsInputs: [
          ...formatedOldMentions,
          ...newUnregisteredContacts,
        ] as CreateMentionInput[],
        image: { id: fileId },
      };

      if (values.scheduledDate && !isPublished) {
        editImagePostInput.scheduled = {
          at: convertToUtcDate(values.scheduledDate, selectedTimeZoneOffset),
          offset: selectedTimeZoneOffset,
          tzCode: values?.tzCode,
        };
      }

      const newPost = await editImagePost({
        variables: {
          input: editImagePostInput,
        },
      });
      successNotification("You've edited your image post!");

      handleOnComplete();
      if (newPost.data) {
        onEditFinish();
      }
    } catch (error) {
      setIsLoading(false);
      errorNotification(
        (error as any).graphQLErrors[0]?.extensions.response?.message ??
          ((error as Error)?.message || 'Error editing image!')
      );
    }
  };

  const uploadImageStep = async () => {
    if (image) {
      try {
        const getUrl = await adminCreatePostImagePresignedUrlV2({
          variables: {
            input: {
              storeId,
              files: [
                {
                  contentType: (image as File).type || '',
                  ext: (image as File).name.split('.').pop() || '',
                },
              ],
            },
          },
        });
        if (getUrl?.data) {
          const uploadImageData = await uploadImageToS3(
            getUrl.data.adminCreatePostImagePresignedUrlV2[0],
            {
              data_url: '',
              file: image,
            }
          );
          return uploadImageData;
        }
      } catch (error) {
        setIsLoading(false);
        errorNotification((error as Error).message || 'Error uploading image!');
      }
    }
  };

  const handleVideoEdit = async (values: FormInputs) => {
    const imageKey = await uploadImageStep();

    const createUnregisteredContactInput =
      (mentionsValues as MentionValues[])?.filter(
        (mention) => !mention?.targetType
      ) || [];

    const unregisterContactsData = await createUnregisteredContact({
      variables: {
        input:
          createUnregisteredContactInput as CreateUnregisteredContactInput[],
      },
    });

    const newUnregisteredContacts =
      (unregisterContactsData?.data?.createUnregisteredContact || [])?.map(
        (contact) => ({
          unregisteredContactId: contact?.id,
        })
      ) || [];

    const oldMentions = (mentionsValues?.filter(
      (mention) => mention?.targetType
    ) || []) as GetMediaPost_getMediaPost_VideoPost_mentions[];
    const formatedOldMentions = formatMentionsInput(oldMentions);

    const editVideoPostInput: EditVideoPostAdminInput = {
      storeId,
      id: mediaFeedId,
      title: values.title,
      body: values.body,
      hashtagInputs: values?.hashtags?.length
        ? formatHashtagInput(values.hashtags)
        : null,
      mentionsInputs: [
        ...formatedOldMentions,
        ...newUnregisteredContacts,
      ] as CreateMentionInput[],
      video: {
        id: mediaFeed && 'video' in mediaFeed ? mediaFeed.video.id : '',
      },
    };

    if (values.scheduledDate && !isPublished) {
      editVideoPostInput.scheduled = {
        at: convertToUtcDate(values.scheduledDate, selectedTimeZoneOffset),
        offset: selectedTimeZoneOffset,
        tzCode: values?.tzCode,
      };
    }

    if (imageKey) {
      editVideoPostInput.thumbnailKey = imageKey;
    } else {
      editVideoPostInput.thumbnailKey = null;
    }

    try {
      const newPost = await editVideoPost({
        variables: {
          input: editVideoPostInput,
        },
      });
      successNotification("You've edited your video post!");

      handleOnComplete();

      if (newPost.data) {
        onEditFinish();
      }
    } catch (error) {
      setIsLoading(false);
      errorNotification(getProperErrorMessage(error, 'Error editing video!'));
    }
  };

  const handleDeletePost = async () => {
    setIsLoading(true);
    try {
      await deleteMediaPost({
        variables: {
          input: {
            storeId,
            id: mediaFeedId,
          },
        },
      });
      successNotification("You've deleted your media post!");

      handleOnComplete();
    } catch (error) {
      setIsLoading(false);
      errorNotification((error as Error).message || 'Error deleting post!');
    }
    setIsLoading(false);
  };

  const handleFormSubmit: SubmitHandler<FormInputs> = async (
    values
  ): Promise<void> => {
    setIsLoading(true);

    if (isEdit) {
      if (isVideo) {
        await handleVideoEdit(values);
      } else {
        await handleImageEdit(values);
      }
    } else {
      if (isVideo) {
        await handleVideoSubmit(values);
      } else {
        await handleImageSubmit(values);
      }
    }
  };

  useEffect(() => {
    register('image');
    register('previewImage');

    return () => {
      unregister('image');
      unregister('previewImage');
    };
  }, [register, unregister]);

  const mediaType = MEDIA_TYPE.map((option) => ({
    ...option,
    disabled:
      isEdit &&
      ((isVideo && option.value === 'image') ||
        (!isVideo && option.value === 'video')),
  }));

  const isVideoFileSizeError = videoFileSize > MAX_VIDEO_SIZE_B;

  const videoFileSizeErrorMessage =
    isVideoFileSizeError && videoFileSize ? (
      <div className={styles.videoFileSizeErrorMessage}>
        The size of uploaded video is larger than 1TB (
        <strong>{bytesToSize(videoFileSize)}</strong>
        ). Please, select a smaller video.
      </div>
    ) : null;

  const noFile = isVideo ? (isEdit ? false : !videoFile) : !image;

  const disabled = isLoading || isVideoFileSizeError || noFile;

  const handleSchedulePostChange = () => {
    setScheduled(!scheduled);
  };

  const disabledFromDate = (current: Moment) => {
    const currentTimeInSelectedTimeZone = moment()
      .add(selectedTimeZoneOffset, 'm')
      .add(new Date().getTimezoneOffset(), 'm')
      .toDate()
      .toUTCString();

    return moment(currentTimeInSelectedTimeZone).diff(current, 'minutes') > 0;
  };

  const disabledFromTime = () => {
    const currentTimeInSelectedTimeZone = moment()
      .add(selectedTimeZoneOffset, 'm')
      .add(new Date().getTimezoneOffset(), 'm');

    const targetMoment = currentTimeInSelectedTimeZone
      .clone()
      .add(5, 'minutes');
    const targetHour = targetMoment.get('hour');

    return {
      disabledHours: () => Array.from(Array(targetHour).keys()),
    };
  };

  const handleApplyImage = useCallback(
    (newImage: File) => {
      setValue('previewImage', URL.createObjectURL(newImage), {
        shouldValidate: true,
      });
      setValue('image', newImage, { shouldValidate: true });
    },
    [setValue]
  );

  const renderUploadedImage = () => {
    if (isEdit && previewImage) {
      return (
        <img
          src={previewImage}
          className={styles.previewImage}
          alt="Post img"
        />
      );
    }

    if (image) {
      return (
        <EasyImageCrop
          imageFile={image}
          aspect={1}
          onApplyImage={handleApplyImage}
          className={cn(styles.croppedImage, {
            [styles.croppedThumbnailImage]: isVideo,
          })}
        />
      );
    }
  };

  const handlePlaceholderClick = () => {
    imageUploadRef.current?.click();
  };

  const handlePreviewImageRemove = () => {
    setValue('image', null);
    setValue('previewImage', null);
  };

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      setValue('image', acceptedFiles[0], { shouldValidate: true });
    },
    [setValue]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: 'image/jpg, image/jpeg, image/png',
  });

  const renderUploadImageDropzone = (
    title = 'Image',
    thumbnailDropzone = false
  ) => {
    return (
      <div
        className={cn(styles.imageContainer, {
          [styles.thumbnailImageContainer]: isVideo,
        })}
      >
        <p className={styles.imageLabel}>{title}</p>
        {image || previewImage ? (
          <div
            className={cn(styles.previewImageWrapper, {
              [styles.previewThumbnailImageWrapper]: isVideo,
            })}
          >
            {renderUploadedImage()}
            <button
              className={styles.deleteButton}
              onClick={handlePreviewImageRemove}
              aria-label="Delete image"
            >
              <DeleteOutlined />
            </button>
          </div>
        ) : (
          <div
            {...getRootProps()}
            className={cn(styles.dropzone, {
              [styles.thumbnailDropzone]: thumbnailDropzone,
            })}
          >
            <input {...getInputProps()} aria-label="upload image" />
            <div className={styles.dragArea}>
              <UploadImagePlaceholder
                onClick={handlePlaceholderClick}
                title="Drop your images here or browse"
                description="JPG and PNG supported"
                error={!!errors.image?.message}
              />
            </div>
          </div>
        )}
      </div>
    );
  };

  const handleUploadVideoButtonClick = () => {
    uploadVideoRef.current?.click();
  };

  const videoPreviewErrorMessage = isVideoFileSizeError ? (
    <span>
      The size of uploaded video is more than our limit (
      <strong>{bytesToSize(videoFileSize)}</strong>
      ). Please, select video smaller than 5 TiB.
    </span>
  ) : null;

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (file) {
      const fileURL = URL.createObjectURL(file);

      setState({
        videoFile: file,
        videoPreviewURL: fileURL,
        videoFileSize: file.size,
        showVideoPreview: getIfVideoSupportedByBrowser(file),
      });
    }
  };

  return (
    <form
      onSubmit={handleSubmit(handleFormSubmit)}
      autoComplete="off"
      aria-label="stream details"
    >
      <TextArea
        placeholder={isVideo ? 'Title' : 'Type Your Media Post'}
        name="title"
        ref={register}
        error={errors?.title?.message}
        className={cn(styles.textarea, styles.nameTextarea)}
        rows={isVideo ? 2 : 6}
      />

      <TextArea
        name="body"
        ref={register}
        placeholder="Body"
        error={errors?.body?.message}
        className={cn(styles.textarea, styles.nameTextarea)}
        rows={6}
      />

      <Checkbox
        className={styles.schedulePostCheckbox}
        name="scheduled"
        checked={scheduled}
        onClick={handleSchedulePostChange}
        ref={register}
      >
        Schedule post
      </Checkbox>

      <div className={styles.datePickerContainer}>
        <Controller
          control={control}
          name="scheduledDate"
          render={({ onChange, onBlur, value }) => {
            const handleChange = (date: any) => {
              onChange(date);
            };

            return (
              <DatePicker
                data-testid="scheduledDate"
                name="scheduledDate"
                onChange={handleChange}
                onBlur={onBlur}
                value={value}
                showTime
                showNow={false}
                format={commonTimeFormat}
                style={{ width: 250 }}
                minuteStep={15}
                disabled={!scheduled}
                disabledDate={disabledFromDate}
                disabledTime={disabledFromTime as any}
              />
            );
          }}
        />
        <Controller
          control={control}
          name="tzCode"
          render={({ onChange }) => {
            return (
              <Select
                className={styles.timeZonePicker}
                placeholder="Select Time Zone"
                value={getTimeZoneLabel(tzCode)}
                onChange={onChange}
                disabled={!scheduled}
              >
                {TIME_ZONE_OPTIONS.map(({ tzCode, label }) => {
                  return (
                    <Option key={tzCode} value={tzCode}>
                      {label}
                    </Option>
                  );
                })}
              </Select>
            );
          }}
        />
      </div>

      <div>
        <Controller
          name="hashtags"
          control={control}
          render={({ onChange }) => (
            <TagsInput
              hashtags={hashtags || []}
              onChange={onChange}
              name="hashtags"
            />
          )}
        />
      </div>

      <div className={styles.mentionsSection}>
        <p className={styles.itemTitle}>Mentioning options</p>
        <Button
          onClick={toggleMentions}
          color="white"
          className={styles.arrowDownIconButton}
          type="button"
        >
          {isMentionsVisible ? (
            <UpOutlined className={styles.arrowDownIcon} />
          ) : (
            <DownOutlined className={styles.arrowDownIcon} />
          )}
        </Button>
      </div>
      {!isMobile && (
        <p className={styles.itemNote}>
          Mention athletes, brands, and organizations by using their names and
          links to directly engage them and increase interaction on your
          content. Write down their names and url links.
        </p>
      )}
      {isMentionsVisible && (
        <Mentions mentions={mentionsValues} onChange={handleMentionChange} />
      )}

      <div className={styles.uploadImage}>
        {!isEdit && (
          <RadioGroup
            className={cn(styles.switch, styles.switchMobile)}
            radioGroupName="isVideo"
            options={mediaType}
            checkedValue={isVideo ? 'video' : 'image'}
            onChange={changeType}
          />
        )}

        {isVideo ? (
          <>
            <p className={styles.imageLabel}>Video</p>

            <div>
              <VideoPreview
                video={videoPreviewURL}
                show={showVideoPreview}
                isCropVisible={false}
                wrapperClassName={styles.previewRoot}
                errorMessage={videoPreviewErrorMessage}
                deviceSize="onboarding"
              />

              <input
                name="video"
                type="file"
                accept="video/*"
                hidden
                onChange={handleFileUpload}
                ref={uploadVideoRef}
                aria-label="upload video"
              />

              {!videoPreviewURL && (
                <button
                  className={styles.videoBlockButton}
                  type="button"
                  onClick={handleUploadVideoButtonClick}
                >
                  <UploadOutlined />

                  <span className={styles.chooseFileLabel}>Upload video</span>
                </button>
              )}

              {videoPreviewURL && !showVideoPreview && (
                <span className={styles.unsupportedLabel}>
                  The video preview is not supported
                </span>
              )}
            </div>
            <div
              className={cn(styles.progressBar, {
                [styles.hideProgressBar]: !videoProgressContainer,
              })}
            >
              {videoProgressContainer}
            </div>

            {renderUploadImageDropzone('Thumbnail Image (optional)', true)}
          </>
        ) : (
          renderUploadImageDropzone()
        )}
      </div>

      {isVideo && videoFileSizeErrorMessage}

      <Button
        className={styles.submitButton}
        type="submit"
        s="default"
        color="harvest-gold"
        loading={isLoading}
        disabled={isEdit ? false : disabled}
      >
        {isEdit ? 'Apply' : 'create'}
      </Button>

      {isEdit && (
        <Button
          className={styles.deletePostButton}
          type="button"
          s="default"
          color="harvest-gold"
          loading={isLoading}
          onClick={handleDeletePost}
        >
          Delete post
        </Button>
      )}
    </form>
  );
};

export default CreateEditMediaFeedStream;

import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { Rate, Modal, Button, Tabs } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
// Constants
import {
  DESIGN_REQUEST_DETAILS_GEN,
  DESIGN_REQUEST_RESULT_DETAILS_GEN,
} from 'constants/routes';
// Api
import { DESIGN_REQUESTS } from 'api/designLab/queries';
import { GET_ADMINS } from 'api/admin/queries';
// Types
import { SortedInfo, TableFilter } from 'ui/Table';
import {
  SortDirection,
  DesignRequestType,
  DesignRequestStatus,
  DesignRequestsOrderBy,
  DesignPriorityType,
  StoreStatus,
} from 'api/graphql-global-types';
import {
  DesignRequests,
  DesignRequestsVariables,
  DesignRequests_designRequests_entities_auditLogs,
  DesignRequests_designRequests_entities_rejections,
  DesignRequests_designRequests_entities_socialMediaPackImages,
} from 'api/designLab/types/DesignRequests';
import { GetAdmins, GetAdminsVariables } from 'api/admin/types/GetAdmins';
// Helpers
import { getStatues } from 'helpers/getDesignRequestStatuses';
import {
  DesignRequestsFormattedData,
  getDesignRequestDueDateCountdown,
  getDesignRequestsFormattedData,
  RequestImage,
} from 'helpers/designRequests';
// UI
import Table, { Column } from 'ui/Table';
// Components
import RequestResultRejects from './components/RequestResultRejects/RequestResultRejects';
import DesignRequestsActions from './components/DesignRequestsActions/DesignRequestsActions';
import ChangeDesignPriority from './components/ChangeDesignPriority/ChangeDesignPriority';
import MerchDesignModal from './components/MerchDesignModal/MerchDesignModal';
import MerchDesignPreviewModal from './components/MerchDesignPreviewModal/MerchDesignPreviewModal';
import RequestResultDownloadLinks from './components/RequestResultsDownloadLinks/RequestResultsDownloadLinks';
import AuditLogs from './components/AuditLogs/AuditLogs';
// Styles
import 'components/common/DesignRequestsTable/DesignRequestTable.scss';

type DesignRequestsTableProps = {
  designerId?: string;
};

const { TabPane } = Tabs;

const DesignRequestsTable: React.FC<DesignRequestsTableProps> = ({
  designerId,
}) => {
  const history = useHistory();

  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [sort, setSortInfo] = useState<SortedInfo<DesignRequestsOrderBy>>({
    order: SortDirection.ASC,
    key: DesignRequestsOrderBy.createdAt,
  });
  const [searchFilters, setSearchFilters] = useState<TableFilter[]>([]);

  const [isMerchDesignResultModalVisible, setMerchDesignResultModal] =
    useState<RequestImage | null>(null);

  const showMerchDesignResultModal = (requestResult: RequestImage | null) => {
    requestResult && setMerchDesignResultModal(requestResult);
  };

  const [socialMediaPack, setSocialMediaPack] = useState<
    DesignRequests_designRequests_entities_socialMediaPackImages[]
  >([]);

  const hideMerchantDesignResultModal = () => {
    setMerchDesignResultModal(null);
  };

  const [itemModalData, setItemModalData] =
    useState<DesignRequestsFormattedData | null>(null);

  const showItemModal = (request: DesignRequestsFormattedData | null) => {
    setItemModalData(request);
  };

  const hideItemModal = () => {
    setItemModalData(null);
  };

  const [tabKey, setTabKey] = useState<string>(designerId ? '' : 'all');

  const handleTabChange = (activeKey: string) => {
    setTabKey(activeKey);
    setSearchFilters([]);
    setCurrentPage(1);
  };

  const getDesignRequestsInput: any = () => {
    let input: any = {
      direction: sort.order,
      orderBy: sort.key,
      limit: pageSize,
      offset: (currentPage > 1 ? currentPage - 1 : 0) * pageSize,
      ...(designerId && {
        designerIds: [designerId],
        designRequestStatuses: [
          DesignRequestStatus.InProgress,
          DesignRequestStatus.Rejected,
          DesignRequestStatus.Accepted,
          DesignRequestStatus.AutoAccepted,
        ],
      }),
    };

    if (tabKey === 'isUnassigned') {
      input = {
        ...input,
        isUnassigned: true,
      };
    }

    if (tabKey === 'isOverdue') {
      input = {
        ...input,
        isOverdue: true,
      };
    }

    if (tabKey === 'isInProgress') {
      input = {
        ...input,
        isInProgress: true,
      };
    }

    searchFilters?.forEach(({ key, value }) => {
      input[key] = value;
    });

    const { status, ...rest } = input;

    return {
      input: {
        ...rest,
        ...(status && { designRequestStatuses: status }),
      },
    };
  };

  const { data, loading } = useQuery<DesignRequests, DesignRequestsVariables>(
    DESIGN_REQUESTS,
    {
      variables: { ...getDesignRequestsInput() },
      fetchPolicy: 'cache-and-network',
    }
  );

  const counters = data?.designRequests?.counters;

  const { data: adminData } = useQuery<GetAdmins, GetAdminsVariables>(
    GET_ADMINS,
    {
      variables: {
        input: {
          limit: 100, //maximum number
          status: [
            StoreStatus.Active,
            StoreStatus.Inactive, // only search for ones with any work done
          ],
        },
      },
      fetchPolicy: 'cache-and-network',
    }
  );

  const requestIdColumn = {
    title: 'Request id',
    dataIndex: DesignRequestsOrderBy.designRequestId,
    key: DesignRequestsOrderBy.designRequestId,
    align: 'left' as const,
    fixed: 'left' as const,
    width: 140,
    sorterType: 'text',
    withSearch: true,
  };
  const storeNameColumn = {
    title: 'Store Name',
    dataIndex: DesignRequestsOrderBy.storeName,
    key: DesignRequestsOrderBy.storeName,
    align: 'left' as const,
    width: 120,
    sorterType: 'text',
    withSearch: true,
  };
  const requestDateColumn = {
    title: 'Request date',
    dataIndex: DesignRequestsOrderBy.createdAt,
    key: DesignRequestsOrderBy.createdAt,
    align: 'center' as const,
    width: 110,
    sorterType: 'date',
    render: (createdAt: string) =>
      moment(createdAt).local().format('MM/DD/YYYY, h:mm a'),
  };
  const dueDateColumn = {
    title: 'Due date',
    dataIndex: DesignRequestsOrderBy.dueDate,
    key: 'dueDatePriority',
    align: 'center' as const,
    width: 80,
    sorterType: 'date',
    render: function DueDateCountdown(
      createdAt: string,
      record: DesignRequestsFormattedData
    ) {
      return <span>{getDesignRequestDueDateCountdown(record)}</span>;
    },
  };

  const requestTypeColumn = {
    title: 'Request type',
    dataIndex: DesignRequestsOrderBy.designRequestType,
    key: DesignRequestsOrderBy.designRequestType,
    align: 'center' as const,
    sorterType: 'text',
    withRadioFilters: true,
    filters: [
      {
        text: 'Merch creation',
        value: DesignRequestType.MerchCreation,
      },
      {
        text: 'Merch creation with logo',
        value: DesignRequestType.MerchCreationWithLogo,
      },
    ],
    filterMultiple: true,
    width: 130,
  };

  const requestPriorityColumn = {
    title: 'Request priority',
    dataIndex: 'priorityType',
    align: 'center' as const,
    width: 120,
    render: function RequestPriority(
      priorityType: DesignPriorityType,
      record: DesignRequestsFormattedData
    ) {
      return (
        <ChangeDesignPriority
          request={record}
          priority={priorityType}
          designerId={designerId}
        />
      );
    },
  };

  const escalatedToColumn = {
    title: 'Escalated to',
    dataIndex: ['escalatedTo', 'email'],
    key: 'escalatedToId',
    align: 'center' as const,
    width: 120,
    withAdminSearch: true,
    adminSearchData: adminData,
    render: function EscalatedTo(escalatedTo: string) {
      return escalatedTo;
    },
  };

  const managedByColumn = {
    title: 'Managed by',
    dataIndex: ['store', 'storeManagementDetails', 'managedBy', 'email'],
    key: 'managedById',
    align: 'center' as const,
    width: 120,
    withAdminSearch: true,
    adminSearchData: adminData,
    render: function ManagedBy(managedBy: string) {
      return managedBy;
    },
  };
  const handleDesignRequestDetailsClick = async (requestId: string) => {
    await history.push(`${DESIGN_REQUEST_DETAILS_GEN}/${requestId}`);
  };

  const handleDesignResultDetailsClick = async (requestId: string) => {
    await history.push(`${DESIGN_REQUEST_RESULT_DETAILS_GEN}/${requestId}`);
  };

  const requestDetailsColumn = {
    title: 'Request details',
    dataIndex: 'requestDetails',
    align: 'center' as const,
    width: 180,
    render: function RequestDetails(requestDetails: RequestImage) {
      return requestDetails?.link?.length ? (
        <div>
          <a
            href={requestDetails.link}
            download={requestDetails.name}
            title={requestDetails.name}
          >
            <DownloadOutlined /> Download details
          </a>
          <div>
            <p>OR</p>
            <Button
              type="primary"
              onClick={() => {
                if (requestDetails.designRequestId) {
                  handleDesignRequestDetailsClick(
                    requestDetails.designRequestId
                  );
                }
              }}
            >
              View details
            </Button>
          </div>
        </div>
      ) : null;
    },
  };

  const assignDateColumn = {
    title: 'Assign date',
    dataIndex: DesignRequestsOrderBy.assignDate,
    key: DesignRequestsOrderBy.assignDate,
    align: 'center' as const,
    width: 125,
    sorterType: 'date',
    render: (assignDate: string | null) =>
      assignDate
        ? moment(assignDate).local().format('MM/DD/YYYY, h:mm a')
        : '-',
  };

  const designerColumn = {
    title: 'Designer',
    dataIndex: DesignRequestsOrderBy.designerName,
    key: DesignRequestsOrderBy.designerName,
    align: 'left' as const,
    width: 110,
    sorterType: 'text',
    withSearch: true,
  };

  const actionColumn = {
    title: 'Action',
    dataIndex: 'id',
    fixed: 'right' as const,
    align: 'center' as const,
    width: 90,
    render: function ActionsWrap(
      id: string,
      record: DesignRequestsFormattedData
    ) {
      return (
        <DesignRequestsActions
          request={record}
          designerId={designerId}
          store={record?.store}
          handleShowModal={showItemModal}
        />
      );
    },
  };

  const statuses = [
    { value: DesignRequestStatus.Archived, text: 'Archived' },
    { value: DesignRequestStatus.Todo, text: 'Todo' },
    { value: DesignRequestStatus.InProgress, text: 'In Progress' },
    { value: DesignRequestStatus.OnReview, text: 'On Review' },
    { value: DesignRequestStatus.AwaitingApproval, text: 'Awaiting Approval' },
    { value: DesignRequestStatus.Accepted, text: 'Accepted' },
    { value: DesignRequestStatus.AutoAccepted, text: 'Auto-Accepted' },
    { value: DesignRequestStatus.Rejected, text: 'Rejected' },
  ];

  const statusColumn = {
    title: 'Status',
    dataIndex: DesignRequestsOrderBy.status,
    key: DesignRequestsOrderBy.status,
    filters: designerId ? statuses : getStatues(tabKey),
    filterMultiple: true,
    withCheckboxFilters: true,
    align: 'center' as const,
    width: 130,
    sorterType: 'text',
  };
  const requestResultColumn = {
    title: 'Request result',
    align: 'center' as const,
    width: 180,
    dataIndex: 'requestResults',
    render: function RequestResult(requestResults: RequestImage[]) {
      return requestResults[0].merchItems?.length ? (
        <Button
          type="primary"
          onClick={() => {
            if (requestResults[0]?.merchItems?.length) {
              showMerchDesignResultModal(requestResults[0]);
              if (requestResults[0]?.socialMediaPack) {
                setSocialMediaPack(requestResults[0]?.socialMediaPack);
              }
            }
          }}
        >
          View product
        </Button>
      ) : (
        <div>
          <RequestResultDownloadLinks requestResults={requestResults} />

          {requestResults[0].link !== '' && (
            <>
              <p>OR</p>
              <Button
                type="primary"
                onClick={() => {
                  if (requestResults[0].designRequestId) {
                    handleDesignResultDetailsClick(
                      requestResults[0].designRequestId
                    );
                  }
                }}
              >
                View details
              </Button>
            </>
          )}
        </div>
      );
    },
  };

  const auditLogsColumn = {
    title: 'Audit logs',
    dataIndex: 'auditLogs',
    align: 'center' as const,
    width: 140,
    render: function AuditLogsWrap(
      auditLogs: DesignRequests_designRequests_entities_auditLogs[]
    ) {
      return <AuditLogs data={auditLogs} />;
    },
  };

  const commentsColumn = {
    title: 'Comments',
    dataIndex: 'rejections',
    align: 'center' as const,
    width: 140,
    render: function CommentsWrap(
      rejections: DesignRequests_designRequests_entities_rejections[]
    ) {
      return <RequestResultRejects data={rejections} />;
    },
  };
  const rateColumn = {
    title: 'Rate',
    dataIndex: DesignRequestsOrderBy.designRate,
    key: DesignRequestsOrderBy.designRate,
    align: 'center' as const,
    width: 180,
    sorterType: 'number',
    render: function RateWrap(designRate: number | null) {
      return <Rate value={designRate || 0} disabled allowHalf count={5} />;
    },
  };

  const adminColumns: Column<
    DesignRequestsFormattedData,
    DesignRequestsOrderBy
  >[] = [
    requestIdColumn,
    storeNameColumn,
    requestDateColumn,
    dueDateColumn,
    assignDateColumn,
    requestTypeColumn,
    requestPriorityColumn,
    requestDetailsColumn,
    requestResultColumn,
    designerColumn,
    managedByColumn,
    escalatedToColumn,
    commentsColumn,
    auditLogsColumn,
    rateColumn,
    statusColumn,
    actionColumn,
  ];

  const designerColumns: Column<
    DesignRequestsFormattedData,
    DesignRequestsOrderBy
  >[] = [
    requestIdColumn,
    storeNameColumn,
    requestDateColumn,
    dueDateColumn,
    assignDateColumn,
    requestTypeColumn,
    requestPriorityColumn,
    requestDetailsColumn,
    commentsColumn,
    statusColumn,
    actionColumn,
  ];

  const renderTable = () => (
    <Table<DesignRequestsFormattedData, DesignRequestsOrderBy>
      columns={designerId ? designerColumns : adminColumns}
      data={getDesignRequestsFormattedData(data)}
      scroll={{ x: 1280 }}
      loading={loading}
      total={data?.designRequests?.total}
      defaultPageSize={pageSize}
      defaultCurrent={currentPage}
      setPageSize={setPageSize}
      setCurrentPage={setCurrentPage}
      searchFilters={searchFilters}
      setSearchFilters={setSearchFilters}
      sortInfo={sort}
      setSortInfo={setSortInfo}
    />
  );

  return (
    <>
      {designerId ? (
        renderTable()
      ) : (
        <Tabs
          activeKey={tabKey}
          onChange={handleTabChange}
          className="countersCustomTabs"
        >
          <TabPane tab="All" key="all">
            {renderTable()}
          </TabPane>

          <TabPane
            tab={`Total unassigned jobs ${counters?.unassignedCount || 0}`}
            key="isUnassigned"
          >
            {renderTable()}
          </TabPane>
          <TabPane
            tab={`Total ongoing jobs ${counters?.inProgressCount || 0}`}
            key="isInProgress"
          >
            {renderTable()}
          </TabPane>
          <TabPane
            tab={`Total delayed jobs ${counters?.pastDueDateCount || 0}`}
            key="isOverdue"
          >
            {renderTable()}
          </TabPane>
        </Tabs>
      )}

      <Modal
        title="Merch design result"
        visible={!!isMerchDesignResultModalVisible}
        footer={null}
        onCancel={hideMerchantDesignResultModal}
        width="90vw"
        destroyOnClose
      >
        <MerchDesignPreviewModal
          request={isMerchDesignResultModalVisible}
          socialMediaPack={socialMediaPack}
          onClose={hideMerchantDesignResultModal}
        />
      </Modal>

      <Modal
        title="Design merch"
        visible={!!itemModalData}
        footer={null}
        onCancel={hideItemModal}
        width="90vw"
      >
        <MerchDesignModal request={itemModalData} onClose={hideItemModal} />
      </Modal>
    </>
  );
};

export default DesignRequestsTable;

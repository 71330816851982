import React, { useEffect, useState } from 'react';
import moment, { Moment } from 'moment';
import { useMutation, useQuery } from '@apollo/client';
import {
  DatePicker,
  Form,
  Input,
  Tag,
  Spin,
  Typography,
  Space,
  Button,
  Modal,
} from 'antd';
import {
  UploadOutlined,
  DeleteOutlined,
  ShareAltOutlined,
} from '@ant-design/icons';
import cn from 'classnames';
// Constants
import { commonTimeFormat } from 'constants/global';
// Api
import { GET_VIDEO_EDIT_REQUESTS_FOR_EDITORS } from 'api/videoLab/queries';
import {
  DELETE_EDITED_VIDEO_BY_ID,
  EDIT_VIDEO_REQUEST_INFO,
} from 'api/videoLab/mutation';
import { GetVideoEditRequests_getVideoEditRequests_entities_editedVideos } from 'api/videoLab/types/GetVideoEditRequests';
import {
  EditVideoRequestInfo,
  EditVideoRequestInfoVariables,
} from 'api/videoLab/types/EditVideoRequestInfo';
import {
  VideoEditRequestStatus,
  VideoEditRequestSubmissionStatus,
} from 'api/graphql-global-types';
// Types
import {
  DeleteEditedVideoById,
  DeleteEditedVideoByIdVariables,
} from 'api/videoLab/types/DeleteEditedVideoById';
// UI
import { errorNotification, successNotification } from 'ui/Notification';
// Helpers
import { VideoEditingRequestsFormattedData } from 'helpers/videoEditingRequest';
import { tagStyles } from 'uiShared/TagWithButtons/helpers';
// Components
import { UploadFinalizedVideo } from '../UploadFinalizedVideo/UploadFinalizedVideo';
import VideoRequestSourceFields from '../../common/VideoRequestSourceFields/VideoRequestSourceFields';
// Styles
import styles from './MyVideoEditRequestDetailsModal.module.scss';

const { Text } = Typography;

export type FormInputs = {
  selectedVideoType: string;
  videoEditorId: string | null;
  createdAt: Moment;
  dueDate: Moment | null;
  rawUrls: string | null;
  adminNotes: string | null;
  notes: string | null;
};

type MyVideoEditRequestDetailsModalProps = {
  videoEditRequest: VideoEditingRequestsFormattedData | null;
  hideDetailsModal: () => void;
  isViewOnly?: boolean;
};

const MyVideoEditRequestDetailsModal = ({
  videoEditRequest,
  hideDetailsModal,
  isViewOnly,
}: MyVideoEditRequestDetailsModalProps): JSX.Element | null => {
  const initialFormValues: FormInputs = {
    selectedVideoType: 'Interview',
    videoEditorId: videoEditRequest?.assignedEditorId || null,
    createdAt: moment(videoEditRequest?.createdAt) || '-',
    dueDate: moment(videoEditRequest?.dueDate) || '-',
    rawUrls: videoEditRequest?.rawUrls?.join(', ') || null,
    adminNotes: videoEditRequest?.adminNotes || null,
    notes: videoEditRequest?.notes || null,
  };
  const [editedVideos, setEditedVideos] = useState<
    GetVideoEditRequests_getVideoEditRequests_entities_editedVideos[]
  >(videoEditRequest?.editedVideos || []);

  const [selectedEditedVideo, setSelectedEditedVideo] =
    useState<GetVideoEditRequests_getVideoEditRequests_entities_editedVideos | null>(
      null
    );
  const [isUploadModalVisible, setIsUploadModalVisible] =
    useState<boolean>(false);

  const showUploadModal = () => setIsUploadModalVisible(true);

  const hideUploadModal = () => {
    setIsUploadModalVisible(false);
    setSelectedEditedVideo(null);
  };

  const [editVideoRequestInfo, { loading: editVideoRequestInfoLoading }] =
    useMutation<EditVideoRequestInfo, EditVideoRequestInfoVariables>(
      EDIT_VIDEO_REQUEST_INFO
    );

  const [deleteEditedVideoById] = useMutation<
    DeleteEditedVideoById,
    DeleteEditedVideoByIdVariables
  >(DELETE_EDITED_VIDEO_BY_ID);

  const { refetch: refetchVideoEditData } = useQuery(
    GET_VIDEO_EDIT_REQUESTS_FOR_EDITORS,
    {
      variables: {
        input: {
          videoEditorIds: [videoEditRequest?.assignedEditorId],
        },
      },
    }
  );

  const [form] = Form.useForm();

  const handleFormFinish = async () => {
    const values = form.getFieldsValue();

    try {
      await editVideoRequestInfo({
        variables: {
          input: {
            id: videoEditRequest?.id || '',
            notes: values.notes,
            status: VideoEditRequestStatus.OnReview,
          },
        },
      });

      successNotification('Information updated successfully');
      hideDetailsModal();
    } catch (err) {
      errorNotification((err as Error)?.message || 'Something went wrong');
    }
  };

  useEffect(() => {
    if (videoEditRequest) {
      form.setFieldsValue({
        editorNotes: videoEditRequest?.notes || '',
      });
    }
  }, [videoEditRequest, form]);

  if (editVideoRequestInfoLoading) {
    return <Spin />;
  }

  const handleEditedVideoClick = (
    editedVideo: GetVideoEditRequests_getVideoEditRequests_entities_editedVideos
  ) => {
    setSelectedEditedVideo(editedVideo);
    showUploadModal();
  };

  const handleRemoveEditedVideo = async (
    editedVideoId: string,
    key: string
  ) => {
    try {
      await deleteEditedVideoById({
        variables: { input: { editedVideoId, key } },
      });
      successNotification('The stream has been deleted successfully');
      handleRefetchVideos();
    } catch (err: any) {
      errorNotification(err?.message);
    }
  };

  const handleRefetchVideos = async () => {
    const { data: refetchedData } = await refetchVideoEditData({
      input: {
        videoEditorIds: [videoEditRequest?.assignedEditorId],
      },
    });
    const videoRequestItemEditedVideos =
      refetchedData?.getVideoEditRequests?.entities?.filter(
        (item: VideoEditingRequestsFormattedData) =>
          item?.id === videoEditRequest?.id
      )[0]?.editedVideos;
    setEditedVideos(videoRequestItemEditedVideos);
  };

  const handleCopyLinkButtonClick = async (url: string) => {
    try {
      await navigator.clipboard.writeText(url);

      successNotification('Link copied');
    } catch (error) {
      errorNotification('Could not copy text');
    }
  };

  // video is rejected if any submission is rejected with the latest reason not being empty
  const checkIsRejected = (
    editedVideo: GetVideoEditRequests_getVideoEditRequests_entities_editedVideos
  ): boolean => {
    const isItemRejected: boolean = editedVideo.reviewSubmissions.some(
      (submission) =>
        submission.status === VideoEditRequestSubmissionStatus.Rejected &&
        submission.rejections
          ? !!submission.rejections[submission.rejections.length - 1].reason
          : false
    );

    return isItemRejected;
  };

  // flag used to determine user options-if the request was rejected,
  // we have different UI and options
  const isReupload: boolean =
    videoEditRequest?.status === VideoEditRequestStatus.Rejected;

  return (
    <>
      <Form
        form={form}
        layout="horizontal"
        name="videoRequestDetailsForm"
        autoComplete="off"
        initialValues={initialFormValues}
        onFinish={handleFormFinish}
      >
        <div>
          <p>Task ID: {videoEditRequest?.id || ''}</p>
        </div>

        <div>
          <p>Video edit request status: {videoEditRequest?.status || ''}</p>
        </div>

        <div>
          <p>Store name: {videoEditRequest?.storeName || ''}</p>
        </div>

        {!!videoEditRequest?.additionalStores.length && (
          <Form.Item label={<Text>Additional store names</Text>}>
            <div>
              {videoEditRequest?.additionalStores.map((store, index) => (
                <Tag key={index} style={tagStyles}>
                  <div className={styles.text}>
                    {store?.storeDetails?.storeName || ''}
                  </div>
                </Tag>
              ))}
            </div>
          </Form.Item>
        )}

        <VideoRequestSourceFields source={videoEditRequest?.videoSource} />

        <Form.Item name="createdAt" label={<Text>Created at</Text>}>
          <DatePicker
            data-testid="createdAt"
            showTime
            format={commonTimeFormat}
            style={{ width: 250 }}
            disabled
          />
        </Form.Item>

        <Form.Item name="dueDate" label={<Text>Due date</Text>}>
          <DatePicker
            data-testid="dueDate"
            showTime
            format={commonTimeFormat}
            style={{ width: 250 }}
            minuteStep={15}
            disabled
          />
        </Form.Item>

        <Form.Item label={<Text>Raw video link</Text>}>
          <div style={{ marginTop: '10px' }}>
            {videoEditRequest?.rawUrls.map((url, index) => (
              <Tag key={index} style={tagStyles}>
                <div className={styles.text}>{url}</div>

                <Button
                  type="link"
                  icon={<ShareAltOutlined />}
                  onClick={() => handleCopyLinkButtonClick(url)}
                  className={styles.tagButton}
                />
              </Tag>
            ))}
          </div>
        </Form.Item>

        <Form.Item label={<Text>Finalized Video</Text>}>
          <div className={styles.editedVideosList}>
            {editedVideos.map((item, index) => {
              const isItemRejected: boolean = checkIsRejected(item);

              // allow delete unless the file is submitted for review-after that point, don't allow any deletions
              // so we are checking if it's not "view only" and if it's also missing review submissions
              const allowDelete =
                !isViewOnly && !item.reviewSubmissions?.length;

              return (
                <Tag
                  key={index}
                  onClick={() => handleEditedVideoClick(item)}
                  className={cn(styles.videoTag, {
                    [styles.videoTagError]: isItemRejected,
                  })}
                >
                  {item.title || `finalized_video_${index}`}
                  {allowDelete && (
                    <Button
                      className={styles.videoTagButton}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleRemoveEditedVideo(item.id, item.key);
                      }}
                    >
                      <DeleteOutlined />
                    </Button>
                  )}
                </Tag>
              );
            })}
          </div>
        </Form.Item>

        {!isViewOnly && !isReupload && (
          <Space className="video-modal-container" direction="vertical">
            <Button
              type="primary"
              onClick={showUploadModal}
              className={styles.addButton}
            >
              {<UploadOutlined />}
              Upload finalized video
            </Button>
          </Space>
        )}

        <Form.Item name="adminNotes" label={<Text>Admin Notes</Text>}>
          <Input disabled />
        </Form.Item>

        <Form.Item name="notes" label={<Text>Editor Notes</Text>}>
          <Input placeholder="Add notes here" disabled={isViewOnly} />
        </Form.Item>

        {!isViewOnly && (
          <Form.Item className={styles.saveButtonContainer}>
            <Button
              type="primary"
              htmlType="submit"
              loading={editVideoRequestInfoLoading}
              disabled={!editedVideos.length}
            >
              Submit for review
            </Button>
          </Form.Item>
        )}
      </Form>

      <Modal
        title="Upload finalized video"
        visible={!!isUploadModalVisible}
        footer={null}
        onCancel={hideUploadModal}
        width={800}
        destroyOnClose
      >
        <UploadFinalizedVideo
          onClose={hideUploadModal}
          isAdmin={false}
          isRejected={
            selectedEditedVideo ? checkIsRejected(selectedEditedVideo) : false
          }
          videoEditRequest={videoEditRequest}
          selectedEditedVideo={selectedEditedVideo}
          handleRefetchVideos={handleRefetchVideos}
          isViewOnly={isViewOnly}
        />
      </Modal>
    </>
  );
};

export default MyVideoEditRequestDetailsModal;

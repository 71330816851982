import React, { useState } from 'react';
import { Button, Image, Typography, Modal } from 'antd';
// Types
import { GetMediaPosts_getMediaPosts_entities } from 'api/mediaPost/types/GetMediaPosts';
// Constants
import { MEDIA_POST } from 'constants/routes';
import { FALLBACK_IMAGE } from 'constants/global';
// Helpers
import { getDateDifference, isVideoPost } from 'helpers/mediaFeed';
// Config
import ENV from 'api/env';
// Components
import CreateEditMediaFeedStream from '../../CreateEditMediaFeed/CreateEditMediaFeed';
// Styles
import styles from './Card.module.scss';

type MediaLibraryCardProps = {
  mediaPost: GetMediaPosts_getMediaPosts_entities;
};

const { Link } = Typography;

const MediaPostCard = ({ mediaPost }: MediaLibraryCardProps): JSX.Element => {
  const created = new Date(mediaPost.createdAt);

  const displayDate = getDateDifference(created);
  const origin = 'Media Feed'; // for now we have only media feed, not importing from social networks

  const thumbnailImage = isVideoPost(mediaPost)
    ? mediaPost.video?.thumbnailUrl || ''
    : mediaPost.image?.url || '';

  const isVideo = 'video' in mediaPost;
  const buttonText = isVideo ? 'Watch' : 'View';

  const server =
    ENV.REACT_APP_ENV === 'development'
      ? 'http://localhost:4000'
      : ENV.REACT_APP_ENV === 'staging'
      ? 'https://stg1.millions.co'
      : 'https://millions.co';

  const linkHref = `${server}/${mediaPost.store.slug}${MEDIA_POST}/${mediaPost.slug}`;

  const [mediaPostModalVisibility, setMediaPostModalVisibility] =
    useState<boolean>(false);

  const handleMediaPostModalOpen = () => {
    setMediaPostModalVisibility(true);
  };

  const handleMediaPostModalClose = () => {
    setMediaPostModalVisibility(false);
  };

  const onCompleteCallback = () => {
    setMediaPostModalVisibility(false);
  };

  return (
    <>
      <div className={styles.root} title={mediaPost.title}>
        <div className={styles.imageWrapper}>
          <Image
            src={thumbnailImage}
            alt={mediaPost.title}
            fallback={FALLBACK_IMAGE}
            width={250}
            height={250}
          />
        </div>

        <div className={styles.textWrapper}>
          <p> {origin}</p>
          <p>{mediaPost.title}</p>
          <p>Status: {mediaPost.status}</p>
          <p> {displayDate}</p>
        </div>

        <div className={styles.buttonsWrapper}>
          <Link href={linkHref} target="_blank">
            {buttonText}
          </Link>
          <Button onClick={handleMediaPostModalOpen}>Edit</Button>
        </div>
      </div>
      <Modal
        title="Edit a media post"
        visible={mediaPostModalVisibility}
        footer={null}
        onCancel={handleMediaPostModalClose}
        width={800}
      >
        <CreateEditMediaFeedStream
          onComplete={onCompleteCallback}
          mediaFeed={mediaPost}
        />
      </Modal>
    </>
  );
};

export default MediaPostCard;

import { gql } from '@apollo/client';

export const DOWNLOAD_CAMPAIGN_OFFERS = gql`
  mutation DownloadCampaignOffers($input: DownloadCampaignOffersInput!) {
    downloadCampaignOffers(input: $input) {
      csvUrl
    }
  }
`;

export const APPROVE_CAMPAIGN = gql`
  mutation ApproveCampaign($input: ApproveCampaignInput!) {
    approveCampaign(input: $input) {
      id
      status
    }
  }
`;

export const DECLINE_CAMPAIGN = gql`
  mutation DeclineCampaign($input: DeclineCampaignInput!) {
    declineCampaign(input: $input) {
      id
      status
    }
  }
`;

export const CREATE_CAMPAIGN_EXAMPLE_FILES = gql`
  mutation CreateCampaignExampleFiles(
    $input: CreateCampaignExampleFilesInput!
  ) {
    createCampaignExampleFiles(input: $input) {
      fields
      key
      url
    }
  }
`;

export const UPDATE_CAMPAIGN = gql`
  mutation UpdateCampaign($input: UpdateCampaignInput!) {
    updateCampaign(input: $input) {
      id
    }
  }
`;

export const ADD_OFFLINE_PAYMENT = gql`
  mutation AddOfflineCampaignPayment($input: AddOfflineCampaignPaymentInput!) {
    addOfflineCampaignPayment(input: $input) {
      id
    }
  }
`;

import React from 'react';
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';
// Components
import { CustomXAxisTick } from './CustomXAxisTick/CustomXAxisTick';
import { CustomYAxisTick } from './CustomYAxisTick/CustomYAxisTick';
import { CustomTooltip } from './CustomTooltip/CustomTooltip';
import { CustomLegend } from './CustomLegend/CustomLegend';

import styles from './DashboardChart.module.scss';

export type DashboardChartData = {
  date: string;
  dayNumber: string;
  merch: number;
  streams: number;
  ama: number;
  tips: number;
};

type DashboardChartProps = {
  data: DashboardChartData[];
  currency?: string;
};

const DashboardChart: React.FC<DashboardChartProps> = ({
  data,
  currency = '',
}) => {
  const merchColor = '#73c64c';
  const streamsColor = '#4394d7';
  const amaColor = '#e15555';
  const tipsColor = '#b512de';
  const memorabiliaColor = '#00FFFF';
  const productsColor = '#FF1493';
  const experienceColor = '#8B4513';

  return (
    <ResponsiveContainer height={250} className={styles.chartWrapper}>
      <LineChart
        height={250}
        data={data}
        margin={{
          top: 0,
          right: 8,
          left: 8,
          bottom: 0,
        }}
      >
        <CartesianGrid stroke="#E5E5E5" horizontal={false} />
        <XAxis
          dataKey="dayNumber"
          stroke="#E5E5E5"
          tick={<CustomXAxisTick />}
          height={50}
        />
        <YAxis
          width={56}
          orientation="left"
          stroke="#E5E5E5"
          tick={<CustomYAxisTick currency={currency} />}
        />
        <Tooltip
          content={(props) => <CustomTooltip {...props} currency={currency} />}
        />
        <Legend iconType="circle" height={18} content={<CustomLegend />} />
        <Line
          type="linear"
          dataKey="experience"
          stroke={experienceColor}
          strokeWidth={2}
          dot={false}
          activeDot={{
            fill: experienceColor,
            stroke: experienceColor,
            strokeWidth: 0,
          }}
        />
        <Line
          type="linear"
          dataKey="memorabilia"
          stroke={memorabiliaColor}
          strokeWidth={2}
          dot={false}
          activeDot={{
            fill: memorabiliaColor,
            stroke: memorabiliaColor,
            strokeWidth: 0,
          }}
        />
        <Line
          type="linear"
          dataKey="merch"
          stroke={merchColor}
          strokeWidth={2}
          dot={false}
          activeDot={{
            fill: merchColor,
            stroke: merchColor,
            strokeWidth: 0,
          }}
        />
        <Line
          type="linear"
          dataKey="products"
          stroke={productsColor}
          strokeWidth={2}
          dot={false}
          activeDot={{
            fill: productsColor,
            stroke: productsColor,
            strokeWidth: 0,
          }}
        />
        <Line
          type="linear"
          dataKey="streams"
          stroke={streamsColor}
          strokeWidth={2}
          dot={false}
          activeDot={{
            fill: streamsColor,
            stroke: streamsColor,
            strokeWidth: 0,
          }}
        />
        <Line
          type="linear"
          dataKey="ama"
          stroke={amaColor}
          strokeWidth={2}
          dot={false}
          activeDot={{
            fill: amaColor,
            stroke: amaColor,
            strokeWidth: 0,
          }}
        />
        <Line
          type="linear"
          dataKey="tips"
          stroke={tipsColor}
          strokeWidth={2}
          dot={false}
          activeDot={{
            fill: tipsColor,
            stroke: tipsColor,
            strokeWidth: 0,
          }}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default DashboardChart;

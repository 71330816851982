import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import moment from 'moment';
import {
  Modal,
  Form,
  TimePicker,
  Checkbox,
  Button,
  Select,
  Typography,
  Input,
} from 'antd';
import { DeleteOutlined, PlusSquareFilled } from '@ant-design/icons';
// Hooks
import { useGetScheduleTimeZones } from 'hooks/useGetScheduleTimeZones';
// Api
import { SET_SCHEDULE } from 'api/schedule/mutations';
import { GET_SCHEDULE } from 'api/schedule/queries';
// Types
import { GetStoreInterviewers_getStoreInterviewers_entities } from 'api/interviewer/types/GetStoreInterviewers';
import { ScheduleType } from 'api/graphql-global-types';
import {
  SetSchedule,
  SetScheduleVariables,
} from 'api/schedule/types/SetSchedule';
import {
  GetSchedule,
  GetScheduleVariables,
  GetSchedule_getSchedule_rules_ScheduleDateRule,
  GetSchedule_getSchedule_rules_ScheduleWeekDayRule,
} from 'api/schedule/types/GetSchedule';
// Helpers
import {
  CheckboxState,
  DateSpecificHours,
  TimeRanges,
  getWeekDayRule,
  getTimezoneOptions,
  weekdays,
  getWeekDayAvailability,
  getDatesAvailability,
} from 'helpers/availability';
// UI
import { errorNotification, successNotification } from 'ui/Notification';
// Components
import SpecificHoursModal from './components/SpecificHoursModal/SpecificHoursModal';
import SpecificHoursList from './components/SpecificHoursList/SpecificHoursList';
// Styles
import styles from './AvailabilityModal.module.scss';

const { Text } = Typography;
const { Option } = Select;

type AvailabilityModalModalProps = {
  onClose: () => void;
  isVisible: boolean;
  interviewer?: GetStoreInterviewers_getStoreInterviewers_entities;
};

const AvailabilityModal = ({
  onClose,
  isVisible,
  interviewer,
}: AvailabilityModalModalProps): JSX.Element => {
  const { data: timeZonesData } = useGetScheduleTimeZones();
  const { data: scheduleData } = useQuery<GetSchedule, GetScheduleVariables>(
    GET_SCHEDULE,
    {
      variables: {
        input: {
          userId: interviewer?.id || '',
          type: ScheduleType.Interview,
        },
      },
      skip: !interviewer?.id,
      fetchPolicy: 'cache-and-network',
    }
  );

  const [setSchedule] = useMutation<SetSchedule, SetScheduleVariables>(
    SET_SCHEDULE
  );

  const timezoneOptions = getTimezoneOptions(timeZonesData);

  const [form] = Form.useForm();
  const weekdayRules = scheduleData?.getSchedule
    ?.rules as GetSchedule_getSchedule_rules_ScheduleWeekDayRule[];

  const dateRules = scheduleData?.getSchedule
    ?.rules as GetSchedule_getSchedule_rules_ScheduleDateRule[];
  const [checkboxState, setCheckboxState] = useState<CheckboxState>({
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
  });

  const [specificHoursList, setSpecificHoursList] = useState<
    DateSpecificHours[]
  >([]);

  const handleRemoveFromSpecificHoursList = (index: number) => {
    const newSpecificHoursList = specificHoursList.filter(
      (_, i) => i !== index
    );
    setSpecificHoursList(newSpecificHoursList);
  };

  const handleAddToSpecificHoursList = (data: DateSpecificHours) => {
    setSpecificHoursList((prev) => [
      ...prev,
      {
        date: data.date,
        timeRanges: data.timeRanges,
      },
    ]);
  };

  const [isDateSpecificModalVisible, setIsDateSpecificModalVisible] =
    useState<boolean>(false);

  const [timeRanges, setTimeRanges] = useState<TimeRanges>({
    monday: [{ from: null, to: null }],
    tuesday: [{ from: null, to: null }],
    wednesday: [{ from: null, to: null }],
    thursday: [{ from: null, to: null }],
    friday: [{ from: null, to: null }],
    saturday: [{ from: null, to: null }],
    sunday: [{ from: null, to: null }],
  });

  const handleDateSpecificModalToggle = () => {
    setIsDateSpecificModalVisible((prev) => !prev);
  };

  const handleCheckboxChange = (day: keyof CheckboxState, checked: boolean) => {
    setCheckboxState((prevState) => ({
      ...prevState,
      [day]: checked,
    }));
  };

  const handleAddTimeRange = (day: keyof CheckboxState) => {
    setTimeRanges((prevState) => ({
      ...prevState,
      [day]: [...prevState[day], { from: null, to: null }],
    }));
  };

  const handleRemoveTimeRange = (day: keyof CheckboxState, index: number) => {
    setTimeRanges((prevState) => ({
      ...prevState,
      [day]: prevState[day].filter((_, i) => i !== index),
    }));
  };

  const handleTimeChange = (
    day: keyof CheckboxState,
    index: number,
    value: any
  ) => {
    const [from, to] = value || [null, null];
    setTimeRanges((prevState) => {
      const newRanges = [...prevState[day]];
      newRanges[index] = { from, to };
      return {
        ...prevState,
        [day]: newRanges,
      };
    });
  };

  const handleSubmit = async () => {
    const values = form.getFieldsValue();

    try {
      const weekDayAvailability = getWeekDayAvailability(
        timeRanges,
        checkboxState
      );
      const datesAvailability = getDatesAvailability(specificHoursList);
      const dates = datesAvailability.map((item) => ({
        ...item,
        intervals: item.intervals
          .map((interval) => {
            if (interval.from === '' && interval.to === '') {
              return [];
            }
            return interval;
          })
          .flat(),
      }));

      await setSchedule({
        variables: {
          input: {
            userId: interviewer?.id || '',
            timezone: values.tzCode,
            dates,
            weekdays: weekDayAvailability,
            type: ScheduleType.Interview,
            slotStepMinutes: Number(values.durationMinutes),
            minimumNoticeMinutes: Number(values.minimumNoticeMinutes),
          },
        },
      });
      successNotification('Availability updated successfully');
      onClose();
    } catch (err) {
      errorNotification((err as Error)?.message || 'Something went wrong');
    }
  };

  const onFormSubmit = async () => {
    try {
      return handleSubmit();
    } catch (err) {
      errorNotification((err as Error)?.message || 'Something went wrong');
    }
  };

  useEffect(() => {
    if (weekdayRules) {
      const monday = getWeekDayRule('monday', weekdayRules);
      const tuesday = getWeekDayRule('tuesday', weekdayRules);
      const wednesday = getWeekDayRule('wednesday', weekdayRules);
      const thursday = getWeekDayRule('thursday', weekdayRules);
      const friday = getWeekDayRule('friday', weekdayRules);
      const saturday = getWeekDayRule('saturday', weekdayRules);
      const sunday = getWeekDayRule('sunday', weekdayRules);

      const weekdays = {
        monday: !!monday,
        tuesday: !!tuesday,
        wednesday: !!wednesday,
        thursday: !!thursday,
        friday: !!friday,
        saturday: !!saturday,
        sunday: !!sunday,
      };

      form.setFieldsValue({
        tzCode: scheduleData?.getSchedule?.timezone,
        minimumNoticeMinutes: scheduleData?.getSchedule?.minimumNoticeMinutes,
        durationMinutes: scheduleData?.getSchedule?.durationMinutes,
        ...weekdays,
      });

      setCheckboxState(weekdays);

      setTimeRanges({
        monday: monday?.intervals.map((interval) => ({
          from: interval.from ? moment(interval.from, 'HH:mm') : null,
          to: interval.to ? moment(interval.to, 'HH:mm') : null,
        })) || [{ from: null, to: null }],
        tuesday: tuesday?.intervals.map((interval) => ({
          from: interval.from ? moment(interval.from, 'HH:mm') : null,
          to: interval.to ? moment(interval.to, 'HH:mm') : null,
        })) || [{ from: null, to: null }],
        wednesday: wednesday?.intervals.map((interval) => ({
          from: interval.from ? moment(interval.from, 'HH:mm') : null,
          to: interval.to ? moment(interval.to, 'HH:mm') : null,
        })) || [{ from: null, to: null }],
        thursday: thursday?.intervals.map((interval) => ({
          from: interval.from ? moment(interval.from, 'HH:mm') : null,
          to: interval.to ? moment(interval.to, 'HH:mm') : null,
        })) || [{ from: null, to: null }],
        friday: friday?.intervals.map((interval) => ({
          from: interval.from ? moment(interval.from, 'HH:mm') : null,
          to: interval.to ? moment(interval.to, 'HH:mm') : null,
        })) || [{ from: null, to: null }],
        saturday: saturday?.intervals.map((interval) => ({
          from: interval.from ? moment(interval.from, 'HH:mm') : null,
          to: interval.to ? moment(interval.to, 'HH:mm') : null,
        })) || [{ from: null, to: null }],
        sunday: sunday?.intervals.map((interval) => ({
          from: interval.from ? moment(interval.from, 'HH:mm') : null,
          to: interval.to ? moment(interval.to, 'HH:mm') : null,
        })) || [{ from: null, to: null }],
      });
    }
  }, [weekdayRules, form, scheduleData]);

  useEffect(() => {
    if (dateRules) {
      const dateRulesFiltered = dateRules.filter(
        (item) => item.__typename === 'ScheduleDateRule'
      );

      if (dateRulesFiltered.length > 0) {
        setSpecificHoursList(
          dateRulesFiltered.map((item) => ({
            date: moment(item.date),
            timeRanges: item.intervals.map((interval) => ({
              from: interval.from ? moment(interval.from, 'HH:mm') : null,
              to: interval.to ? moment(interval.to, 'HH:mm') : null,
            })),
          }))
        );
      }
    }
  }, [dateRules]);

  return (
    <Modal
      title="Interviewer Availability"
      destroyOnClose
      visible={isVisible}
      okText="Save"
      cancelText="Cancel"
      onOk={onFormSubmit}
      onCancel={onClose}
      width="90vw"
    >
      <Form
        form={form}
        layout="horizontal"
        name="availabilityForm"
        className={styles.wrapper}
      >
        <div>
          <h2>Weekly hours</h2>
          <Form.Item
            name="minimumNoticeMinutes"
            label={<Text>Minimum notice in minutes</Text>}
            rules={[
              {
                required: true,
                message: 'Please enter the minimum notice',
              },
            ]}
          >
            <Input type="number" style={{ width: 180 }} />
          </Form.Item>

          <Form.Item
            name="durationMinutes"
            label={<Text>Duration in minutes:</Text>}
            rules={[
              {
                required: true,
                message: 'Please enter the duration',
              },
            ]}
          >
            <Input type="number" style={{ width: 180 }} />
          </Form.Item>
          <div className={styles.timezoneSelect}>
            <Form.Item
              name="tzCode"
              label={<Text>Time Zone</Text>}
              rules={[
                {
                  required: true,
                  message: 'Please select time zone',
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Select time zone"
                optionFilterProp="children"
              >
                {timezoneOptions.map(({ tzName, label }) => {
                  return (
                    <Option key={tzName} value={tzName}>
                      {label}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </div>

          <div className={styles.weekly}>
            {weekdays.map((day) => (
              <div key={day} className={styles.day}>
                <div className={styles.checkbox}>
                  <Form.Item
                    name={day}
                    valuePropName="checked"
                    label={day}
                    className={styles.checkboxItem}
                  >
                    <Checkbox
                      onChange={(e) =>
                        handleCheckboxChange(
                          day as keyof CheckboxState,
                          e.target.checked
                        )
                      }
                    />
                  </Form.Item>
                </div>
                <div className={styles.timeRanges}>
                  {timeRanges[day as keyof CheckboxState].map(
                    (range, index) => (
                      <div key={index} className={styles.timeRange}>
                        <Form.Item>
                          <TimePicker.RangePicker
                            format="HH:mm"
                            minuteStep={15}
                            value={[range.from, range.to]}
                            onChange={(value) =>
                              handleTimeChange(
                                day as keyof CheckboxState,
                                index,
                                value
                              )
                            }
                            disabled={
                              !checkboxState[day as keyof CheckboxState]
                            }
                          />
                        </Form.Item>

                        {index > 0 && (
                          <Button
                            disabled={
                              !checkboxState[day as keyof CheckboxState]
                            }
                            onClick={() =>
                              handleRemoveTimeRange(
                                day as keyof CheckboxState,
                                index
                              )
                            }
                          >
                            <DeleteOutlined /> Delete
                          </Button>
                        )}
                      </div>
                    )
                  )}
                  <Button
                    type="dashed"
                    onClick={() =>
                      handleAddTimeRange(day as keyof CheckboxState)
                    }
                    disabled={!checkboxState[day as keyof CheckboxState]}
                  >
                    <PlusSquareFilled /> Add
                  </Button>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className={styles.specific}>
          <h2>Date-specific hours</h2>
          <p>
            Override your availability for specific dates when your hours differ
            from your regular weekly hours.
          </p>

          <SpecificHoursModal
            isVisible={isDateSpecificModalVisible}
            onClose={handleDateSpecificModalToggle}
            specificHoursList={specificHoursList}
            setSpecificHoursList={setSpecificHoursList}
            handleAddToSpecificHoursList={handleAddToSpecificHoursList}
          />

          <Button type="primary" onClick={handleDateSpecificModalToggle}>
            Add date-specific hours
          </Button>

          {specificHoursList.length > 0 && specificHoursList[0].date && (
            <SpecificHoursList
              list={specificHoursList}
              onRemove={handleRemoveFromSpecificHoursList}
            />
          )}
        </div>
      </Form>
    </Modal>
  );
};

export default AvailabilityModal;
